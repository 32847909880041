import { X } from "lucide-react"
import { AudioSource, ContactType, Goal, PromptType, TranscriptSource } from "./consts"
import { jsonSafeValueToDate, jsonSafeValueToMaybeDate } from "./typing"


export type Person = {
  person_id: string,
  team_id: string,
  person_name: string,
}

export function jsonSafeToPerson (value: Record<string, any>): Person {
  return {
    person_id: value.person_id,
    team_id: value.team_id,
    person_name: value.person_name
  }
}

export type Contact = {
  person_id: string,
  team_id: string,
  contact_type: ContactType,
  contact_value: string,
}

export function jsonSafeToContact (value: Record<string, any>): Contact {
  return {
    person_id: value.person_id,
    team_id: value.team_id,
    contact_type: value.contact_type,
    contact_value: value.contact_value,
  }
}

export type Party = {
  session_id: string,
  party_code: number,
  person_id: string,
}

export function jsonSafeToParty (value: Record<string, any>): Party {
  return {
    session_id: value.session_id,
    party_code: value.party_code,
    person_id: value.person_id,
  }
}

export type Note = {
  note_id: string,
  user_id: string,
  note: string,
  received_at: Date,
  session_id: string | null,
  note_source: string,
}

export function jsonSafeToNote (value: Record<string, any>): Note {
  return {
    note_id: value.note_id,
    user_id: value.user_id,
    note: value.note,
    received_at: jsonSafeValueToDate(value.received_at),
    session_id: value.session_id,
    note_source: value.note_source
  }
}

export type Session = {
  session_id: string,
  user_id: string,
  conversation_id: string
  requested_time: Date,
  realtime_hostname: string,
  scheduled_start: Date
  scheduled_end: Date
  team_id: string | null
  conversation_end: Date | null
  rep_party_code: number | null
  prospect_party_code: number | null
  prospect_person_id: string | null
  prospect_phone_value: string | null    
  prospect_recorded: boolean | null
  platform: Platform
  extension_version: string | null
  bot_id: string | null
}


export function jsonSafeToSession (value: Record<string, any>): Session {
  return {
    session_id: value.session_id,
    user_id: value.user_id,
    team_id: value.team_id,
    conversation_id: value.conversation_id,
    requested_time: jsonSafeValueToDate(value.requested_time),
    realtime_hostname: value.realtime_hostname,
    scheduled_start: jsonSafeValueToDate(value.scheduled_start),
    scheduled_end: jsonSafeValueToDate(value.scheduled_end),
    prospect_person_id: value.prospect_person_id,
    prospect_phone_value: value.prospect_phone_value,
    platform: value.platform,
    extension_version: value.extension_version,
    bot_id: value.bot_id,
    conversation_end: value.conversation_end ? jsonSafeValueToDate(value.conversation_end) : null,
    rep_party_code: value.rep_party_code,
    prospect_party_code: value.prospect_party_code,
    prospect_recorded: value.prospect_recorded,
  }
}

export type Transcript = {
  conversation_id: string
  party_code: number
  start: Date
  end: Date
  transcript_source: TranscriptSource
  bucket_key: string
  has_bucket: boolean
  audio_source: AudioSource | null
  run_id: string | null
  ext_transcript_stream_id: number | null
}

export function jsonSafeToTranscript (value: Record<string, any>): Transcript {
  return {
    conversation_id: value.conversation_id,
    party_code: value.party_code,
    start: jsonSafeValueToDate(value.start),
    end: jsonSafeValueToDate(value.end),
    transcript_source: value.transcript_source,
    bucket_key: value.bucket_key,
    has_bucket: value.has_bucket,
    audio_source: value.audio_source,
    run_id: value.run_id,
    ext_transcript_stream_id: value.ext_transcript_stream_id,
  }
}

export type NoteDisposition = {
  note_id: string,
  label: string,
  disposition: string,
}

export function jsonSafeToNoteDisposition (value: Record<string, any>): NoteDisposition {
  return {
    note_id: value.note_id,
    label: value.label,
    disposition: value.disposition,
  }
}

export type Display = {
  client_id: string,
  prompt_id: number,
  user_id: string,
  prompt_type: PromptType,
  prompt_text: string | null,
  start: Date,
  end: Date| null,
}

export function jsonSafeToDisplay (value: Record<string, any>): Display {
  return {
    client_id: value.client_id,
    prompt_id: value.prompt_id,
    user_id: value.user_id,
    prompt_type: value.prompt_type,
    prompt_text: value.prompt_text,
    start: jsonSafeValueToDate(value.start),
    end: jsonSafeValueToMaybeDate(value.end),
  }
}

export type SubmitNoteParams = {
  'note_source': string
  'notes': string
  'dispositions': {
    'label': string,
    'value': string,
  }[]
}


export const NO_PARTY_ROLE = 'NO_PARTY_ROLE'
export const NO_LOOKBACK_RULE = 'NO_LOOKBACK_RULE'

export enum PartyRole {
  REP = 'REP',
  PROSPECT = 'PROSPECT'
}

export const PartyRoleToRegularCase: {[key in PartyRole]: string} = {
  [PartyRole.REP]: 'Rep',
  [PartyRole.PROSPECT]: 'Prospect'
}

export const PARTY_ROLE_TO_HUMAN_READABLE: {[key in PartyRole]: string} = {
  [PartyRole.REP]: 'Rep Time (%)',
  [PartyRole.PROSPECT]: 'Prospect Time (%)',
}


export enum Sound {
    TONE = 'TONE',  // includes tones over music
    MUSIC = 'MUSIC',
    SPEECH = 'SPEECH',
    SPEECH_OVER_MUSIC = 'SPEECH_OVER_MUSIC',
    BAD_DATA = 'BAD_DATA',  // corrupted and choppy data
    BACKGROUND = 'BACKGROUND'
}

export enum Counterpart {

    //////////////////////////////////////////////////
  // a live human
  //////////////////////////////////////////////////
  NOT_GATEKEEPER = 'NOT_GATEKEEPER',

  GATEKEEPER = 'GATEKEEPER',
  // people with a primary function of routing calls or controlling access, e.g. receptionists or assistants

  VOICEMAIL = 'VOICEMAIL',
  // recordings from the prospect phone system which primarily solicit a voice message to be recorded.
  // this includes:
  //   (a) follow-up menus (press one to send your message)
  //   (b) the end-of-voicemail beep
  //   (c) replay of a voicemail

  MENU = 'MENU',
  // recordings from the prospect phone system which primarily soliciting numeric user input.
  // includes contiguous advertisements from the same speaker that play before the menu starts
  // and ambiguous voicemail-like greeters that are not clearly voicemails
  // this includes notices that play before the call

  HOLD = 'HOLD',
  // recordings from the prospect phone system which primarily solicit the suer to wait
  // this includes
  //   (a) music
  //   (b) recorded interludes that play throughout a call (sometimes on top of music)
  //   (c) special unique recordings at the start of a hold, e.g. 'please wait while we connect your call'



    // tones
    RINGING = 'RINGING',

    // recordings
    CARRIER = 'CARRIER',
    //recordings from the phone infrastructure system or a third party (google voice, robokiller)
    // Ex: 'your call could not be completed as dialed'
    // Ex: 'this number is using robokiller to screen calls...' (ca1254654b1e11ed913095cb8eb5a87e)
    UNKNOWN = 'UNKNOWN',
}
export const MISC_COUNTERPART_FILTER_VALUE = 'MISC'

export const IS_COUNTERPART_MISC: {[key in Counterpart]: boolean} = {
  [Counterpart.RINGING]: true,
  [Counterpart.CARRIER]: true,
  [Counterpart.VOICEMAIL]: false,
  [Counterpart.MENU]: true,
  [Counterpart.HOLD]: true,
  [Counterpart.GATEKEEPER]: false,
  [Counterpart.NOT_GATEKEEPER]: false,
  [Counterpart.UNKNOWN]: true,
}

export const COUNTERPART_TO_HUMAN_READABLE: {[key in Counterpart]: string} = {
  [Counterpart.RINGING]: 'Ringing',
  [Counterpart.CARRIER]: 'Carrier',
  [Counterpart.VOICEMAIL]: 'Voicemail',
  [Counterpart.MENU]: 'Menu',
  [Counterpart.HOLD]: 'Hold',
  [Counterpart.GATEKEEPER]: 'Gatekeeper',
  [Counterpart.NOT_GATEKEEPER]: 'Prospect',
  [Counterpart.UNKNOWN]: 'Unknown'
}



export enum Remark {
  // permission
  C_ASKS = 'C_ASKS',
  R_DENY = 'R_DENY',
  R_BUSY = 'R_BUSY',
  R_APPROVE = 'R_APPROVE',
  
  // role
  C_NAME_CHECK = 'C_NAME_CHECK',
  R_NAME_DENY = 'R_NAME_DENY',
  R_NAME_CONFIRM = 'R_NAME_CONFIRM',
  R_NAME_STATE = 'R_NAME_STATE',
  C_TITLE_CHECK = 'C_TITLE_CHECK',
  R_TITLE_DENY = 'R_TITLE_DENY',
  R_TITLE_CONFIRM = 'R_TITLE_CONFIRM',
  R_TITLE_STATE = 'R_TITLE_STATE',
  C_RESP_CHECK = 'C_RESP_CHECK',
  R_RESP_NOT_RELEVANT = 'R_RESP_NOT_RELEVANT',
  R_RESP_NOT_DECISION_MAKER = 'R_RESP_NOT_DECISION_MAKER',
  R_RESP_DESCRIBE = 'R_RESP_DESCRIBE',
  C_TRANSFER_ASK = 'C_TRANSFER_ASK',
  R_TRANSFER_DENY = 'R_TRANSFER_DENY',
  R_TRANSFER_ATTEMPT = 'R_TRANSFER_ATTEMPT',
  C_REFERRAL_ASK = 'C_REFERRAL_ASK',
  R_REFERRAL_DENY = 'R_REFERRAL_DENY',
  R_REFERRAL_PROVIDE = 'R_REFERRAL_PROVIDE',
  R_IS_HOLD = 'R_IS_HOLD',
  R_IS_VOICEMAIL = 'R_IS_VOICEMAIL',
  R_IS_MENU = 'R_IS_MENU',
  R_IS_CARRIER = 'R_IS_CARRIER',
  
  // previous steps
  CALL_OUTBOUND = 'CALL_OUTBOUND',
  CALL_INBOUND = 'CALL_INBOUND',
  CALL_UNSPECIFIED = 'CALL_UNSPECIFIED',
  EMAIL_OUTBOUND = 'EMAIL_OUTBOUND',
  EMAIL_INBOUND = 'EMAIL_INBOUND',
  EMAIL_UNSPECIFIED = 'EMAIL_UNSPECIFIED',
  MEETING_SCHEDULED = 'MEETING_SCHEDULED',
  MEETING_COMPLETED = 'MEETING_COMPLETED',
  FORM = 'FORM',
  TRIAL = 'TRIAL',
  OTHER = 'OTHER',
  UNSPECIFIED = 'UNSPECIFIED',
  
  // next steps
  MEETING_TIME_SET = 'MEETING_TIME_SET',
  R_EMAIL_ADDRESS_CONFIRM = 'R_EMAIL_ADDRESS_CONFIRM',
  C_CALL_OFFER = 'C_CALL_OFFER',
  R_CALL_DENY = 'R_CALL_DENY',
  R_CALL_APPROVE = 'R_CALL_APPROVE',
  C_EMAIL_OFFER = 'C_EMAIL_OFFER',
  R_EMAIL_DENY = 'R_EMAIL_DENY',
  R_EMAIL_APPROVE = 'R_EMAIL_APPROVE',
  C_DELAYED_OFFER = 'C_DELAYED_OFFER',
  R_DELAYED_DENY = 'R_DELAYED_DENY',
  R_DELAYED_APPROVE = 'R_DELAYED_APPROVE',
  C_MEETING_OFFER = 'C_MEETING_OFFER',
  R_MEETING_REQUEST = 'R_MEETING_REQUEST',
  R_MEETING_DENY = 'R_MEETING_DENY',
  R_MEETING_CONFIRM = 'R_MEETING_CONFIRM',
  C_INBOUND_OFFER = 'C_INBOUND_OFFER',
  R_INBOUND_OFFER = 'R_INBOUND_OFFER',
  C_OTHER_OFFER = 'C_OTHER_OFFER',
  R_OTHER_APPROVE = 'R_OTHER_APPROVE',
  R_OTHER_DENY = 'R_OTHER_DENY',
  C_NONE_COMPANY = 'C_NONE_COMPANY',
  C_NONE_RECIPIENT = 'C_NONE_RECIPIENT',
  
  // product
  C_PRODUCT_DESCRIBE = 'C_PRODUCT_DESCRIBE',
  C_PRODUCT_ASK = 'C_PRODUCT_ASK',
  R_PRODUCT_ASK = 'R_PRODUCT_ASK',
  R_PRODUCT_NO_NEED = 'R_PRODUCT_NO_NEED',
  R_PRODUCT_NO_BUDGET = 'R_PRODUCT_NO_BUDGET',
  R_PRODUCT_DELAY = 'R_PRODUCT_DELAY',
  R_PRODUCT_INTERESTED = 'R_PRODUCT_INTERESTED',
  C_BUSINESS_ASK = 'C_BUSINESS_ASK',
  R_BUSINESS_DESCRIBE = 'R_BUSINESS_DESCRIBE',
  R_BUSINESS_SATISFIED = 'R_BUSINESS_SATISFIED',
  R_BUSINESS_NOT_SATISFIED = 'R_BUSINESS_NOT_SATISFIED',
  C_COMPETITORS_ASK = 'C_COMPETITORS_ASK',
  R_COMPETITORS_NOT_USING = 'R_COMPETITORS_NOT_USING',
  R_COMPETITORS_EVALUATING = 'R_COMPETITORS_EVALUATING',
  R_COMPETITORS_USING = 'R_COMPETITORS_USING'
}

export const REMARK_TO_SHOW_TRANSCRIPT: {[k in Remark]: boolean} = {
  [Remark.C_ASKS]: false,
  [Remark.R_DENY]: false,
  [Remark.R_BUSY]: true,
  [Remark.R_APPROVE]: true,
  [Remark.C_NAME_CHECK]: false,
  [Remark.C_TITLE_CHECK]: false,
  [Remark.R_NAME_DENY]: true,
  [Remark.R_NAME_CONFIRM]:false,
  [Remark.R_NAME_STATE]: false,
  [Remark.R_TITLE_DENY]: true,
  [Remark.R_TITLE_CONFIRM]: false,
  [Remark.R_TITLE_STATE]: true,
  [Remark.C_RESP_CHECK]: false,
  [Remark.R_RESP_NOT_RELEVANT]: true,
  [Remark.R_RESP_NOT_DECISION_MAKER]: true,
  [Remark.R_RESP_DESCRIBE]: true,
  [Remark.C_TRANSFER_ASK]: false,
  [Remark.R_TRANSFER_DENY]: false,
  [Remark.R_TRANSFER_ATTEMPT]: false,
  [Remark.C_REFERRAL_ASK]: true,
  [Remark.R_REFERRAL_DENY]: true,
  [Remark.R_REFERRAL_PROVIDE]: true,
  [Remark.R_IS_HOLD]: false,
  [Remark.R_IS_VOICEMAIL]: false,
  [Remark.R_IS_MENU]: false,
  [Remark.R_IS_CARRIER]: false,
  [Remark.CALL_OUTBOUND]: true,
  [Remark.CALL_INBOUND]: true,
  [Remark.CALL_UNSPECIFIED]: true,
  [Remark.EMAIL_OUTBOUND]: true,
  [Remark.EMAIL_INBOUND]: true,
  [Remark.EMAIL_UNSPECIFIED]: true,
  [Remark.MEETING_SCHEDULED]: true,
  [Remark.MEETING_COMPLETED]: true,
  [Remark.FORM]: true,
  [Remark.TRIAL]: true,
  [Remark.OTHER]: true,
  [Remark.UNSPECIFIED]: false,
  [Remark.MEETING_TIME_SET]: false,
  [Remark.R_EMAIL_ADDRESS_CONFIRM]: false,
  [Remark.C_CALL_OFFER]: false,
  [Remark.R_CALL_DENY]: false,
  [Remark.R_CALL_APPROVE]: false,
  [Remark.C_EMAIL_OFFER]: false,
  [Remark.R_EMAIL_DENY]: true,
  [Remark.R_EMAIL_APPROVE]: true,
  [Remark.C_DELAYED_OFFER]: false,
  [Remark.R_DELAYED_DENY]: true,
  [Remark.R_DELAYED_APPROVE]: true,
  [Remark.C_MEETING_OFFER]: true,
  [Remark.R_MEETING_REQUEST]: true,
  [Remark.R_MEETING_DENY]: true,
  [Remark.R_MEETING_CONFIRM]: true,
  [Remark.C_INBOUND_OFFER]: false,
  [Remark.R_INBOUND_OFFER]: false,
  [Remark.C_OTHER_OFFER]: true,
  [Remark.R_OTHER_APPROVE]: true,
  [Remark.R_OTHER_DENY]: true,
  [Remark.C_NONE_COMPANY]: false,
  [Remark.C_NONE_RECIPIENT]: false,
  [Remark.C_PRODUCT_DESCRIBE]: true,
  [Remark.C_PRODUCT_ASK]: true,
  [Remark.R_PRODUCT_ASK]: true,
  [Remark.R_PRODUCT_NO_NEED]: true,
  [Remark.R_PRODUCT_NO_BUDGET]: true,
  [Remark.R_PRODUCT_DELAY]: true,
  [Remark.R_PRODUCT_INTERESTED]: true,
  [Remark.C_BUSINESS_ASK]: true,
  [Remark.R_BUSINESS_DESCRIBE]: true,
  [Remark.R_BUSINESS_SATISFIED]: true,
  [Remark.R_BUSINESS_NOT_SATISFIED]: true,
  [Remark.C_COMPETITORS_ASK]: false,
  [Remark.R_COMPETITORS_NOT_USING]: true,
  [Remark.R_COMPETITORS_EVALUATING]: true,
  [Remark.R_COMPETITORS_USING]: true
}

export const REMARK_TO_HUMAN_READABLE: {[k in Remark]: string} = {
  [Remark.C_ASKS]: "Asks permission for call",
  [Remark.R_DENY]: "Rejects taking call",
  [Remark.R_BUSY]: "Is currently busy to take call",
  [Remark.R_APPROVE]: "Agrees to listen to rep",
  [Remark.C_NAME_CHECK]: "Confirms prospect name",
  [Remark.C_TITLE_CHECK]: "Asks about title",
  [Remark.R_NAME_DENY]: "Responds they are not the intended prospect",
  [Remark.R_NAME_CONFIRM]: "Verifies they are the intended prospect",
  [Remark.R_NAME_STATE]: "States their name",
  [Remark.R_TITLE_DENY]: "States that they are not in that role",
  [Remark.R_TITLE_CONFIRM]: "Confirms they are in the relevant role",
  [Remark.R_TITLE_STATE]: "States their role",
  [Remark.C_RESP_CHECK]: "Asks about responsibilities",
  [Remark.R_RESP_NOT_RELEVANT]: "Indicates the call is not relevant to their role",
  [Remark.R_RESP_NOT_DECISION_MAKER]: "Indicates they are not a decision maker",
  [Remark.R_RESP_DESCRIBE]: "Describes their role",
  [Remark.C_TRANSFER_ASK]: "Asks to be transferred to prospect",
  [Remark.R_TRANSFER_DENY]: "Rejects reps transfer request",
  [Remark.R_TRANSFER_ATTEMPT]: "Attempt to transfer rep",
  [Remark.C_REFERRAL_ASK]: "Asks for a referral",
  [Remark.R_REFERRAL_DENY]: "Does not provide a referral",
  [Remark.R_REFERRAL_PROVIDE]: "Provides referral",
  [Remark.R_IS_HOLD]: "Call is on hold",
  [Remark.R_IS_VOICEMAIL]: "Voicemail",
  [Remark.R_IS_MENU]: "Menu",
  [Remark.R_IS_CARRIER]: "Carrier Message",
  [Remark.CALL_OUTBOUND]: "Received a previous call from rep",
  [Remark.CALL_INBOUND]: "Previously made a call to rep's company",
  [Remark.CALL_UNSPECIFIED]: "Discussion on previous call",
  [Remark.EMAIL_OUTBOUND]: "Received emails from rep",
  [Remark.EMAIL_INBOUND]: "Emailed rep's company",
  [Remark.EMAIL_UNSPECIFIED]: "Discussion on previous emails between rep and prospect",
  [Remark.MEETING_SCHEDULED]: "Already scheduled a meeting with rep's company",
  [Remark.MEETING_COMPLETED]: "Has already met with rep's company",
  [Remark.FORM]: "Submitted a form on the website",
  [Remark.TRIAL]: "Is participating in a trial of rep's product",
  [Remark.OTHER]: "Previous interaction being discussed",
  [Remark.UNSPECIFIED]: "Unspecified previous interaction",
  [Remark.MEETING_TIME_SET]: "Meeting time scheduling discussion",
  [Remark.R_EMAIL_ADDRESS_CONFIRM]: "Confirms their email address",
  [Remark.C_CALL_OFFER]: "Offers to / states they will give a call back",
  [Remark.R_CALL_DENY]: "Says they do not want a call back",
  [Remark.R_CALL_APPROVE]: "Agrees to the call back",
  [Remark.C_EMAIL_OFFER]: "Confirms sending email",
  [Remark.R_EMAIL_DENY]: "Says they do not want an email",
  [Remark.R_EMAIL_APPROVE]: "Agrees to receiving an email",
  [Remark.C_DELAYED_OFFER]: "Offers to reach back out in a while",
  [Remark.R_DELAYED_DENY]: "Says they do not want a call back",
  [Remark.R_DELAYED_APPROVE]: "Agrees to being reached back out in a while",
  [Remark.C_MEETING_OFFER]: "Offers a scheduled meeting",
  [Remark.R_MEETING_REQUEST]: "Requests a scheduled meeting",
  [Remark.R_MEETING_DENY]: "Rejects the meeting",
  [Remark.R_MEETING_CONFIRM]: "Agrees to the meeting",
  [Remark.C_INBOUND_OFFER]: "Tells prospect to reach out if they ever have any questions",
  [Remark.R_INBOUND_OFFER]: "Offers to reach back out if they have questions",
  [Remark.C_OTHER_OFFER]: "Invites prospect to a touchpoint",
  [Remark.R_OTHER_APPROVE]: "Agrees to touchpoint",
  [Remark.R_OTHER_DENY]: "Rejects touchpoint",
  [Remark.C_NONE_COMPANY]: "States they will not contact prospect's company any further",
  [Remark.C_NONE_RECIPIENT]: "States they will not contact prospect any further",
  [Remark.C_PRODUCT_DESCRIBE]: "Describes product",
  [Remark.C_PRODUCT_ASK]: "Asks about value of product to prospect",
  [Remark.R_PRODUCT_ASK]: "Asks about product",
  [Remark.R_PRODUCT_NO_NEED]: "Says they have no need for product",
  [Remark.R_PRODUCT_NO_BUDGET]: "Says they have no budget to consider new tools",
  [Remark.R_PRODUCT_DELAY]: "Says the timing does not work out to consider new tools",
  [Remark.R_PRODUCT_INTERESTED]: "Expresses interest in the product",
  [Remark.C_BUSINESS_ASK]: "Asks about business",
  [Remark.R_BUSINESS_DESCRIBE]: "Describes their business",
  [Remark.R_BUSINESS_SATISFIED]: "States their business needs are met",
  [Remark.R_BUSINESS_NOT_SATISFIED]: "States their business needs are not met",
  [Remark.C_COMPETITORS_ASK]: "Asks if prospect is using any competitors",
  [Remark.R_COMPETITORS_NOT_USING]: "Says they are not using any competitors",
  [Remark.R_COMPETITORS_EVALUATING]: "Says they are evaluating competitors",
  [Remark.R_COMPETITORS_USING]: "Says they are using a competitor"
}

export const OBJECTION_REMARKS: Remark[] = [
  Remark.R_RESP_NOT_RELEVANT,
  Remark.R_RESP_NOT_DECISION_MAKER,
  Remark.R_PRODUCT_NO_NEED,
  Remark.R_PRODUCT_NO_BUDGET,
  Remark.R_COMPETITORS_USING,
  Remark.R_DENY,
  Remark.R_NAME_DENY,
  Remark.R_TITLE_DENY
]

export const RemarksToShortForm: Map<Remark, string> = new Map([
  // objection remarks
  [Remark.R_RESP_NOT_RELEVANT, 'Not relevant'],
  [Remark.R_RESP_NOT_DECISION_MAKER, 'Not a decision maker'],
  [Remark.R_PRODUCT_NO_NEED, 'No need'],
  [Remark.R_PRODUCT_NO_BUDGET, 'No budget'],
  [Remark.R_COMPETITORS_USING, 'Using competitor'],
  [Remark.R_DENY, 'Call brush off'],
  [Remark.R_NAME_DENY, 'Wrong person'],
  [Remark.R_TITLE_DENY, 'Wrong role'],

  // behavior remarks
  [Remark.C_ASKS, "Asks permission for call"],
  [Remark.C_REFERRAL_ASK, "Asks for referral"],
  [Remark.C_MEETING_OFFER, "Asks for meeting"],
  [Remark.C_CALL_OFFER, "Offers to call back"],
  [Remark.C_BUSINESS_ASK, "Asks about competitors"],

  // outcome remarks
  [Remark.R_REFERRAL_PROVIDE, "Provides referral"],
  [Remark.R_CALL_APPROVE, "Agrees to call back"],
  [Remark.R_MEETING_CONFIRM, "Confirms meeting"],
  [Remark.R_PRODUCT_INTERESTED, "Expressed product interest"] 
])

export const REMARKS_OBJECTIONS: Map<Remark, [string, string]> = new Map([
  [Remark.R_RESP_NOT_RELEVANT, ['Not relevant', 'Objections']],
  [Remark.R_RESP_NOT_DECISION_MAKER, ['Not a decision maker', 'Objections']],
  [Remark.R_PRODUCT_NO_NEED, ['No need', 'Objections']],
  [Remark.R_PRODUCT_NO_BUDGET, ['No budget', 'Objections']],
  [Remark.R_PRODUCT_DELAY, ['Timing issue', 'Objections']],
  [Remark.R_COMPETITORS_USING, ['Using competitor', 'Objections']],
  [Remark.R_DENY, ['Call brush off', 'Objections']],
  [Remark.R_NAME_DENY, ['Wrong person', 'Objections']],
  [Remark.R_TITLE_DENY, ['Wrong role', 'Objections']]
])

export const BEHAVIOR_REMARKS = [
  Remark.C_ASKS,
  Remark.C_REFERRAL_ASK,
  Remark.C_MEETING_OFFER,
  Remark.C_CALL_OFFER,
  Remark.C_BUSINESS_ASK,
]

export const REMARKS_BEHAVIORS: Map<Remark, [string, string]> = new Map([
  [Remark.C_ASKS, ['Asks permission for call', 'Behavior']],
  [Remark.C_REFERRAL_ASK, ['Asks for referral', 'Behavior']],
  [Remark.C_MEETING_OFFER, ['Asks for meeting', 'Behavior']],
  [Remark.C_CALL_OFFER, ['Offers to call back', 'Behavior']],
  [Remark.C_BUSINESS_ASK, ['Asks about business', 'Behavior']],
  [Remark.C_COMPETITORS_ASK, ['Asks about competitors', 'Behavior']],
])

export const OUTCOME_REMARKS = [
  Remark.R_REFERRAL_PROVIDE,
  Remark.R_CALL_APPROVE,
  Remark.R_MEETING_CONFIRM,
  Remark.R_PRODUCT_INTERESTED,
]

export const REMARKS_OUTCOMES: Map<Remark, [string, string]> = new Map([
  [Remark.R_REFERRAL_PROVIDE, ['Provides referral', 'Outcomes']],
  [Remark.R_CALL_APPROVE, ['Agrees to call back',  'Outcomes']],
  [Remark.R_MEETING_CONFIRM, ['Confirms meeting or reconnect',  'Outcomes']],
  [Remark.MEETING_TIME_SET, ['Meeting scheduling',  'Outcomes']],
  [Remark.R_PRODUCT_INTERESTED, ['Expresses interest',  'Outcomes']],
])


export const REMARKS_ALLOWED: Remark[] = [
  Remark.R_DENY, // states they do not want call to continue explicitly
  Remark.R_NAME_DENY, // negative assertion of name
  Remark.R_TITLE_DENY, // negativge assertion of title
  Remark.R_TRANSFER_DENY, // transfer attempt rejected
  Remark.R_RESP_NOT_DECISION_MAKER, // not decision maker
  Remark.R_RESP_NOT_RELEVANT, // not relevant
  Remark.R_CALL_DENY, // denies a call back
  Remark.R_EMAIL_DENY, // denies email 
  Remark.R_DELAYED_DENY, // denies delayed call back
  Remark.R_MEETING_DENY, // denies meeting offer
  Remark.R_PRODUCT_NO_NEED, // no need for product
  Remark.R_PRODUCT_NO_BUDGET, // budget objection
  Remark.R_COMPETITORS_USING, // using competitor
  Remark.C_ASKS, // permision assking
  Remark.C_REFERRAL_ASK, // asked for referral
  Remark.C_BUSINESS_ASK, // asks about business
  Remark.C_RESP_CHECK, // asks about responsibilities
  Remark.C_MEETING_OFFER, // offers meeting
  Remark.C_CALL_OFFER, // offers to call back
  Remark.C_EMAIL_OFFER, // offers to send email
  Remark.R_EMAIL_APPROVE, // approves receiving email
  Remark.R_PRODUCT_INTERESTED, // expresses interest in product
  Remark.R_MEETING_CONFIRM, // confirms meeting
  Remark.R_CALL_APPROVE, // approves a call back
  Remark.R_EMAIL_APPROVE, // approves receiving email
  Remark.R_REFERRAL_PROVIDE, // provides referral
]

export const OBJECTION_REMARKS_ALLOWED: Remark[] = [
  Remark.R_DENY,
  Remark.R_NAME_DENY,
  Remark.R_TITLE_DENY,
  Remark.R_TRANSFER_DENY,
  Remark.R_RESP_NOT_DECISION_MAKER,
  Remark.R_RESP_NOT_RELEVANT,
  Remark.R_CALL_DENY,
  Remark.R_DELAYED_DENY,
  Remark.R_EMAIL_DENY,
  Remark.R_MEETING_DENY,
  Remark.R_PRODUCT_NO_NEED,
  Remark.R_PRODUCT_NO_BUDGET,
  Remark.R_COMPETITORS_USING,
]

export const REP_BEHAVIOR_REMARKS_ALLOWED: Remark[] = [
  Remark.C_ASKS,
  Remark.C_REFERRAL_ASK,
  Remark.C_BUSINESS_ASK,
  Remark.C_RESP_CHECK,
  Remark.C_MEETING_OFFER,
  Remark.C_CALL_OFFER,
  Remark.C_EMAIL_OFFER,
]

export enum REMARK_ELIGIBLE {
  REP_ONLY = 'REP_ONLY',
  PROSPECT_ONLY = 'PROSPECT_ONLY',
  BOTH = 'BOTH',
}

export const RemarkToEligible = Object.fromEntries(Array.from(Object.values(Remark)).map((value: Remark) => {
  return [value as Remark, value.startsWith('C_') ? REMARK_ELIGIBLE.REP_ONLY : value.startsWith('R_') ? REMARK_ELIGIBLE.PROSPECT_ONLY : REMARK_ELIGIBLE.BOTH]
}))


export const OBJECTIONS_TO_HUMAN_READABLE: Map<Remark, string> = new Map([
[Remark.R_DENY, 'Bad time'],
[Remark.R_NAME_DENY, 'Wrong Name'],
[Remark.R_TITLE_DENY, 'Wrong Role'],
[Remark.R_TRANSFER_DENY, 'No Transfer'],
[Remark.R_RESP_NOT_DECISION_MAKER, 'No Decision Maker'],
[Remark.R_CALL_DENY, 'Busy Now'],
[Remark.R_EMAIL_DENY, 'No Email'],
[Remark.R_DELAYED_DENY, 'Delay'],
[Remark.R_MEETING_DENY, 'Meeting Denied'],
[Remark.R_PRODUCT_NO_NEED, 'No Product Need'],
[Remark.R_PRODUCT_NO_BUDGET, 'No Budget'],
[Remark.R_COMPETITORS_USING, 'Using Competitors'],
[Remark.R_RESP_NOT_RELEVANT, 'Not Relevant'],
])


export const REMARK_FILTERS_TO_HUMAN_READABLE = new Map([...Array.from(REMARKS_OBJECTIONS), ...Array.from(REMARKS_BEHAVIORS), ...Array.from(REMARKS_OUTCOMES)].map((value: [Remark, [string, string]]) => [value[0], value[1][0]]))

export const ANALYTICS_REMARKS_TO_HUMAN_READABLE: Map<Remark, [string, string]> = new Map([...Array.from(REMARKS_BEHAVIORS.entries()), ...Array.from(REMARKS_OBJECTIONS.entries()), ...Array.from(REMARKS_OUTCOMES.entries())])

export enum AGENT_TYPE {
  'LINKEDIN' = 'LINKEDIN',
  'COMPANY_WEBSITE' = 'COMPANY_WEBSITE'
}

export enum STAGE {
    DIALED = 'DIALED',
    CONTACT = 'CONTACT',  
    PITCHED = 'PITCHED',
    CONVERSATION = 'CONVERSATION',
    BOOKED = 'BOOKED'
}

export const StageToHumanReadable: {[k in STAGE]: string} = {
  [STAGE.DIALED]: "Dialed",
  [STAGE.CONTACT]: "Live Prospect",
  [STAGE.PITCHED]: "Pitched",
  [STAGE.CONVERSATION]: "Conversation",
  [STAGE.BOOKED]: "Meeting booked"
}

export type SubTeam = {
  subteam_id: string
  team_id: string
  subteam_name: string
}


export enum Platform {
  SALESLOFT = 'SALESLOFT',
  OUTREACH = 'OUTREACH',
  ORUM = 'ORUM',
  HUBSPOT = 'HUBSPOT',
  APOLLO = 'APOLLO',
  NOOKS = 'NOOKS',
  OPENPHONE = 'OPENPHONE',
  FIVENINE = 'FIVENINE',
  TRELLUS_DIALER = 'TRELLUS_DIALER',
  GONG_ENGAGE = 'GONG_ENGAGE',
  FRESHWORKS = 'FRESHWORKS',
  SALESFORCE = 'SALESFORCE',
  BLOOBIRDS = 'BLOOBIRDS',
}

export const PLATFORM_TO_HUMAN_READABLE: {
  [k in Platform]: string;
} = {
  [Platform.SALESLOFT]: "Salesloft",
  [Platform.OUTREACH]: "Outreach",
  [Platform.ORUM]: "Orum",
  [Platform.HUBSPOT]: "Hubspot",
  [Platform.APOLLO]: "Apollo",
  [Platform.TRELLUS_DIALER]: "Trellus",
  [Platform.GONG_ENGAGE]: "Gong Engage",
  [Platform.NOOKS]: "Nooks",
  [Platform.OPENPHONE]: "OpenPhone",
  [Platform.FIVENINE]: "Five9",
  [Platform.FRESHWORKS]: "Freshworks",
  [Platform.SALESFORCE]: "Salesforce",
  [Platform.BLOOBIRDS]: "Bloobirds",
};



export type SubteamView = {
  subteam_view_id: string
  subteam_view_name: string
  is_disjoint: boolean
  temp_view_id?: string 
}

export function jsonSafeToSubteamView(v: Record<string, any>): SubteamView {
  return {
    'subteam_view_id': v.subteam_view_id,
    'subteam_view_name': v.subteam_view_name,
    'is_disjoint': v.is_disjoint
  }
}

export type Subteam = {
  subteam_id: string
  subteam_view_id: string
  subteam_name: string
  temp_subteam_id?: string,
  temp_subteam_view_id?: string
}

export function jsonSafeToSubteam(v: Record<string, any>): Subteam {
  return {
    'subteam_id': v.subteam_id,
    'subteam_view_id': v.subteam_view_id,
    'subteam_name': v.subteam_name
  }
}

export type SubteamMember = {
  subteam_id: string
  user_id: string
  temp_subteam_id?: string
}

export function jsonSafeToSubteamMemeber(v: Record<string, any>): SubteamMember {
  return {
    'subteam_id': v.subteam_id,
    'user_id': v.user_id
  }
}

export type UserGroup = {
  user_group_id: string
  user_group_name: string
  team_id: string
}

export function jsonSafeToUserGroup(v: Record<string, any>): UserGroup {
  return {
    'user_group_id': v.user_group_id,
    'user_group_name': v.user_group_name,
    'team_id': v.team_id,
  }
}

export type UserGroupMembership = {
  user_group_id: string
  member_id: string
  member_is_group: boolean
  team_id: string
}

export function jsonSafeToUserGroupMembership(v: Record<string, any>): UserGroupMembership {
  return {
    'user_group_id': v.user_group_id,
    'member_id': v.member_id,
    'member_is_group': v.member_is_group,
    'team_id': v.team_id,
  }
}

export type ExternalAccount = {
  external_account_id: string
  external_account_name: string
  team_id: string
  is_active: boolean
}

export function jsonSafeToExternalAccount(v: Record<string, any>): ExternalAccount {
  return {
    'external_account_id': v.external_account_id,
    'external_account_name': v.external_account_name,
    'team_id': v.team_id,
    'is_active': v.is_active,
  }
}

export type ExternalAccountLink = {
  external_account_link_id: string
  external_account_id: string
  team_id: string
  platform_login: string
}

export function jsonSafeToExternalAccountLink(v: Record<string, any>): ExternalAccountLink {
  return {
    'external_account_link_id': v.external_account_link_id,
    'external_account_id': v.external_account_id,
    'team_id': v.team_id,
    'platform_login': v.platform_login,
  }
}

export enum MusicWebsite {
  SPOTIFY='SPOTIFY',
  YOUTUBE='YOUTUBE'
}

export enum DialingMode {
  MULTI_NUMBER_ATTEMPT = 'MULTI_NUMBER_ATTEMPT',
  SINGLE_NUMBER_ATTEMPT = 'SINGLE_NUMBER_ATTEMPT'
}

export enum OutreachModalOnClickSetting {
  RECORD_ALWAYS = 'RECORD_ALWAYS',
  RECORD_ONE_PARTY = 'RECORD_ONE_PARTY'
}

export enum PHONE_TYPE {
  MOBILE = 'MOBILE',
  WORK = 'WORK',
  HOME = 'HOME',
  VOIP = 'VOIP',
  OTHER = 'OTHER',
}

export const PHONE_TYPE_TO_HUMAN_READABLE = {
  [PHONE_TYPE.MOBILE]: 'Mobile',
  [PHONE_TYPE.WORK]: 'Work',
  [PHONE_TYPE.HOME]: 'Home',
  [PHONE_TYPE.VOIP]: 'VoIP',
  [PHONE_TYPE.OTHER]: 'Other'
}

export const NEW_TO_OLD_PHONE_TYPES = {
  [PHONE_TYPE.MOBILE]: PHONE_TYPE.MOBILE,
  [PHONE_TYPE.WORK]: PHONE_TYPE.WORK,
  [PHONE_TYPE.HOME]: PHONE_TYPE.OTHER,
  [PHONE_TYPE.VOIP]: PHONE_TYPE.MOBILE,
  [PHONE_TYPE.OTHER]: PHONE_TYPE.OTHER
}

export const DEFAULT_POWER_RANKINGS = [PHONE_TYPE.MOBILE, PHONE_TYPE.VOIP, PHONE_TYPE.WORK, PHONE_TYPE.HOME, PHONE_TYPE.OTHER]

export type PhonePreference = {
  'active': boolean,
  'double_taps': number,
  'order': number
}


// possible we get some in position 0->5...
export function cleanUpOrdering(mp: Map<PHONE_TYPE, PhonePreference>):  Map<PHONE_TYPE, PhonePreference> {
  const x = Array.from(mp.entries()).map((v) => {return {'type': v[0], 'value': v[1]}})
  const numberValues = x.length
  const toReturnList: [PHONE_TYPE, PhonePreference][] = []
  const duplicate = [...x]
  duplicate.sort((a, b) => a.value.order - b.value.order)
  for (let i = 0; i < numberValues; i++) {
    const potentialValue = duplicate[0]
    const matchingValues = duplicate.filter((v) => v.value.order === potentialValue.value.order)
    matchingValues.sort((a, b) => DEFAULT_POWER_RANKINGS.indexOf(a.type) - DEFAULT_POWER_RANKINGS.indexOf(b.type))
    toReturnList.push([matchingValues[0].type, {...matchingValues[0].value, 'order': i}])
    const matchingValue = matchingValues[0]
    const idx = duplicate.findIndex((a) => a.type === matchingValue.type)
    duplicate.splice(idx, 1)
  }
  return new Map(toReturnList)
}


export enum NUMBER_ROTATION_TYPES {
  GEOGRAPHIC_NUMBER_MATCH = 'GEOGRAPHIC_NUMBER_MATCH',
  DIAL_LIMIT_ROTATION = 'DIAL_LIMIT_ROTATION',
}

export const NUMBER_ROTATION_TYPES_TO_HUMAN_READABLE: {[k in NUMBER_ROTATION_TYPES]: string} = {
  [NUMBER_ROTATION_TYPES.GEOGRAPHIC_NUMBER_MATCH]: 'Closest # by geography',
  [NUMBER_ROTATION_TYPES.DIAL_LIMIT_ROTATION]: 'Switch every X dials'
}


export type NumberRotationPreference = {
  'rotation_active': boolean,
  'rotation_style': NUMBER_ROTATION_TYPES,
  'switch_frequency': number 
}

export enum SETTING_TYPES {
  RANGE='RANGE',
  TOGGLE='TOGGLE',
  SELECTOR='SELECTOR',
  NUMBER_INPUT='NUMBER_INPUT',
  SPECIAL_INPUT='SPECIAL_INPUT',
}

export enum SETTING  {
  TIME_TO_DIAL='time_to_dial',
  TIME_TO_LOG='time_to_log',
  AUTO_PROGRESS_LIVE_ACTIVE='auto_progress_live_active',
  AUTO_PROGRESS_TIME='auto_progress_time',
  HANGUP_RINGING_ACTIVE='hangup_ringing_active',
  HANGUP_RINGING_TIME='hangup_ringing_time',
  HANGUP_VOICEMAIL_ACTIVE='hangup_voicemail_active',
  HANGUP_VOICEMAIL_TIME='hangup_voicemail_time',
  HANGUP_MENU_ACTIVE='hangup_menu_active',
  HANGUP_MENU_TIME='hangup_menu_time',
  MUSIC_WEBSITE='music_website',
  LOCAL_CALL_TIME_MIN='local_call_time_min',
  LOCAL_CALL_TIME_MAX='local_call_time_max',
  VALIDATE_TIMEZONE_VIA_NUMBER='validate_timezone_via_number',
  DIALING_MODE='dialing_mode',
  PHONE_PREFERENCE='phone_preference',
  LINKEDIN_AUTO_OPEN='linkedin_auto_open',
  COMPANY_WEBSITE_AUTO_OPEN='company_website_auto_open',
  SALESFORCE_URL_AUTO_OPEN='salesforce_url_auto_open',
  NO_AUTO_COMPLETE_TASK='no_auto_complete_task',
  ACTION_ON_BAD_NUMBER='action_on_bad_number',
  NO_SUMMARY_AUTOMATION='no_summary_automation',
  NUMBER_ROTATION_PREFERENCE='number_rotation_preference',
  USE_CUSTOM_PHONE_FIELDS='use_custom_phone_fields',
  SKIP_TASK_WITHOUT_NUMBER='skip_task_without_number',
  HIDE_WRONG_NUMBER_ELEMENT='hide_wrong_number_element',
  NO_LOG_ONLY='no_log_only',

  PRIMARY_ALWAYS='primary_always',
  PRIMARY_FIRST='primary_first',
  VERIFIED_ALWAYS='verified_always',
  DNC_ALWAYS='dnc_always',
  OUTREACH_MODAL_CLICK_SETTING='outreach_modal_click_setting',
  ALL_RECORDS='all_records',
  MAX_NUMBER_LINES='max_number_lines',
}

export const SETTING_IS_APPLICABLE_FOR_DIALER: {[k in SETTING]: (dialer: Platform) => boolean} = {
  // ** synced settings **//
  [SETTING.TIME_TO_DIAL]: (dialer: Platform) => true,
  [SETTING.TIME_TO_LOG]: (dialer: Platform) => true,
  [SETTING.AUTO_PROGRESS_LIVE_ACTIVE]: (dialer: Platform) => true,
  [SETTING.AUTO_PROGRESS_TIME]: (dialer: Platform) => true,
  [SETTING.HANGUP_RINGING_ACTIVE]: (dialer: Platform) => true,
  [SETTING.HANGUP_RINGING_TIME]: (dialer: Platform) => true,
  [SETTING.HANGUP_VOICEMAIL_ACTIVE]: (dialer: Platform) => true,
  [SETTING.HANGUP_VOICEMAIL_TIME]: (dialer: Platform) => true,
  [SETTING.HANGUP_MENU_ACTIVE]: (dialer: Platform) => true,
  [SETTING.HANGUP_MENU_TIME]: (dialer: Platform) => true,
  [SETTING.MUSIC_WEBSITE]: (dialer: Platform) => true,
  [SETTING.LOCAL_CALL_TIME_MIN]: (dialer: Platform) => true,
  [SETTING.LOCAL_CALL_TIME_MAX]: (dialer: Platform) => true,
  [SETTING.VALIDATE_TIMEZONE_VIA_NUMBER]: (dialer: Platform) => true,
  [SETTING.DIALING_MODE]: (dialer: Platform) => true,
  [SETTING.PHONE_PREFERENCE]: (dialer: Platform) => true,
  [SETTING.LINKEDIN_AUTO_OPEN]: (dialer: Platform) => true,
  [SETTING.COMPANY_WEBSITE_AUTO_OPEN]: (dialer: Platform) => true,
  [SETTING.SALESFORCE_URL_AUTO_OPEN]: (dialer: Platform) => true,
  [SETTING.NO_AUTO_COMPLETE_TASK]: (dialer: Platform) => true,
  [SETTING.ACTION_ON_BAD_NUMBER]: (dialer: Platform) => [Platform.APOLLO, Platform.OUTREACH, Platform.SALESLOFT].includes(dialer),
  [SETTING.NO_SUMMARY_AUTOMATION]: (dialer: Platform) => true,
  [SETTING.NUMBER_ROTATION_PREFERENCE]: (dialer: Platform) => [Platform.OUTREACH].includes(dialer),
  [SETTING.SKIP_TASK_WITHOUT_NUMBER]: (dialer: Platform) => [Platform.APOLLO, Platform.OUTREACH, Platform.SALESLOFT].includes(dialer),
  [SETTING.HIDE_WRONG_NUMBER_ELEMENT]: (dialer: Platform) => [Platform.APOLLO, Platform.OUTREACH, Platform.SALESLOFT].includes(dialer),
  [SETTING.NO_LOG_ONLY]: (dialer: Platform) => true,
  // *** for apollo *** //
  [SETTING.PRIMARY_ALWAYS]: (dialer: Platform) => [Platform.APOLLO].includes(dialer),
  [SETTING.PRIMARY_FIRST]: (dialer: Platform) => [Platform.APOLLO].includes(dialer),
  [SETTING.VERIFIED_ALWAYS]: (dialer: Platform) => [Platform.APOLLO].includes(dialer),
  // *** for outreach *** ///
  [SETTING.OUTREACH_MODAL_CLICK_SETTING]: (dialer: Platform) => [Platform.OUTREACH].includes(dialer),
  // *** for hubspot *** ///
  [SETTING.ALL_RECORDS]: (dialer: Platform) => [Platform.HUBSPOT].includes(dialer),
  [SETTING.USE_CUSTOM_PHONE_FIELDS]: (dialer: Platform) => [Platform.SALESLOFT].includes(dialer),
  [SETTING.DNC_ALWAYS]: (dialer: Platform) => [Platform.APOLLO].includes(dialer),
  [SETTING.MAX_NUMBER_LINES]: (dialer: Platform) => true
}

export const SETTING_TO_ADJUSTMENT_TO_USER_READABLE: {[k in SETTING]: (value: any) => any} = {
  [SETTING.TIME_TO_DIAL]: (value) => value,
  [SETTING.TIME_TO_LOG]: (value) => value,
  [SETTING.AUTO_PROGRESS_LIVE_ACTIVE]: (value) => value,
  [SETTING.AUTO_PROGRESS_TIME]: (value) => value,
  [SETTING.HANGUP_RINGING_ACTIVE]: (value) => value,
  [SETTING.HANGUP_RINGING_TIME]: (value) => value,
  [SETTING.HANGUP_VOICEMAIL_ACTIVE]: (value) => value,
  [SETTING.HANGUP_VOICEMAIL_TIME]: (value) => value,
  [SETTING.HANGUP_MENU_ACTIVE]: (value) => value,
  [SETTING.HANGUP_MENU_TIME]: (value) => value,
  [SETTING.MUSIC_WEBSITE]: (value) => value,
  [SETTING.LOCAL_CALL_TIME_MIN]: (value) => value,
  [SETTING.LOCAL_CALL_TIME_MAX]: (value) => value !== null ? value - 12 : value,
  [SETTING.VALIDATE_TIMEZONE_VIA_NUMBER]: (value) => value,
  [SETTING.DIALING_MODE]: (value) => value,
  [SETTING.PHONE_PREFERENCE]: (value) => value,
  [SETTING.LINKEDIN_AUTO_OPEN]: (value) => value,
  [SETTING.COMPANY_WEBSITE_AUTO_OPEN]: (value) => value,
  [SETTING.SALESFORCE_URL_AUTO_OPEN]: (value) => value,
  [SETTING.NO_AUTO_COMPLETE_TASK]: (value) => value,
  [SETTING.ACTION_ON_BAD_NUMBER]: (value) => value,
  [SETTING.NO_SUMMARY_AUTOMATION]: (value) => value,
  [SETTING.NUMBER_ROTATION_PREFERENCE]: (value) => value,
  [SETTING.PRIMARY_ALWAYS]: (value) => value,
  [SETTING.PRIMARY_FIRST]: (value) => value,
  [SETTING.VERIFIED_ALWAYS]: (value) => value,
  [SETTING.OUTREACH_MODAL_CLICK_SETTING]: (value) => value,
  [SETTING.ALL_RECORDS]: (value) => value,
  [SETTING.USE_CUSTOM_PHONE_FIELDS]: (value) => value,
  [SETTING.DNC_ALWAYS]: (value) => value,
  [SETTING.SKIP_TASK_WITHOUT_NUMBER]: (value) => value,
  [SETTING.HIDE_WRONG_NUMBER_ELEMENT]: (value) => value,
  [SETTING.NO_LOG_ONLY]: (value) => value,
  [SETTING.MAX_NUMBER_LINES]: (value) => value
}

export const SETTING_TO_ADJUSTMENT_TO_BACKEND: {[k in SETTING]: (value: any) => any} = {
  [SETTING.TIME_TO_DIAL]: (value) => value,
  [SETTING.TIME_TO_LOG]: (value) => value,
  [SETTING.AUTO_PROGRESS_LIVE_ACTIVE]: (value) => value,
  [SETTING.AUTO_PROGRESS_TIME]: (value) => value,
  [SETTING.HANGUP_RINGING_ACTIVE]: (value) => value,
  [SETTING.HANGUP_RINGING_TIME]: (value) => value,
  [SETTING.HANGUP_VOICEMAIL_ACTIVE]: (value) => value,
  [SETTING.HANGUP_VOICEMAIL_TIME]: (value) => value,
  [SETTING.HANGUP_MENU_ACTIVE]: (value) => value,
  [SETTING.HANGUP_MENU_TIME]: (value) => value,
  [SETTING.MUSIC_WEBSITE]: (value) => value,
  [SETTING.LOCAL_CALL_TIME_MIN]: (value) => value,
  [SETTING.LOCAL_CALL_TIME_MAX]: (value) => value !== null ? value + 12 : value,
  [SETTING.VALIDATE_TIMEZONE_VIA_NUMBER]: (value) => value,
  [SETTING.DIALING_MODE]: (value) => value,
  [SETTING.PHONE_PREFERENCE]: (value) => value,
  [SETTING.LINKEDIN_AUTO_OPEN]: (value) => value,
  [SETTING.COMPANY_WEBSITE_AUTO_OPEN]: (value) => value,
  [SETTING.SALESFORCE_URL_AUTO_OPEN]: (value) => value,
  [SETTING.NO_AUTO_COMPLETE_TASK]: (value) => value,
  [SETTING.ACTION_ON_BAD_NUMBER]: (value) => value,
  [SETTING.NO_SUMMARY_AUTOMATION]: (value) => value,
  [SETTING.NUMBER_ROTATION_PREFERENCE]: (value) => value,
  [SETTING.PRIMARY_ALWAYS]: (value) => value,
  [SETTING.PRIMARY_FIRST]: (value) => value,
  [SETTING.VERIFIED_ALWAYS]: (value) => value,
  [SETTING.OUTREACH_MODAL_CLICK_SETTING]: (value) => value,
  [SETTING.ALL_RECORDS]: (value) => value,
  [SETTING.USE_CUSTOM_PHONE_FIELDS]: (value) => value,
  [SETTING.DNC_ALWAYS]: (value) => value,
  [SETTING.SKIP_TASK_WITHOUT_NUMBER]: (value) => value,
  [SETTING.HIDE_WRONG_NUMBER_ELEMENT]: (value) => value,
  [SETTING.NO_LOG_ONLY]: (value) => value,
  [SETTING.MAX_NUMBER_LINES]: (value) => value
}

export const SETTING_TO_DEFAULT_VALUE: {[k in SETTING]: (dialer: Platform) => any} = {
  // ** synced settings **//
  [SETTING.TIME_TO_DIAL]: (dialer) => 4,
  [SETTING.TIME_TO_LOG]: (dialer) => 4,
  [SETTING.AUTO_PROGRESS_LIVE_ACTIVE]: (dialer) => true,
  [SETTING.AUTO_PROGRESS_TIME]: (dialer) => 60,
  [SETTING.HANGUP_RINGING_ACTIVE]: (dialer) => true,
  [SETTING.HANGUP_RINGING_TIME]: (dialer) => 30,
  [SETTING.HANGUP_VOICEMAIL_ACTIVE]: (dialer) => true,
  [SETTING.HANGUP_VOICEMAIL_TIME]: (dialer) => 5,
  [SETTING.HANGUP_MENU_ACTIVE]: (dialer) => false,
  [SETTING.HANGUP_MENU_TIME]: (dialer) => 3,
  [SETTING.MUSIC_WEBSITE]: (dialer) => MusicWebsite.SPOTIFY,
  [SETTING.LOCAL_CALL_TIME_MIN]: (dialer) => 8,
  [SETTING.LOCAL_CALL_TIME_MAX]: (dialer) => 18,
  [SETTING.VALIDATE_TIMEZONE_VIA_NUMBER]: (dialer) => false,
  [SETTING.DIALING_MODE]: (dialer) => DialingMode.MULTI_NUMBER_ATTEMPT,
  [SETTING.PHONE_PREFERENCE]: (dialer) => {
    return new Map(DEFAULT_POWER_RANKINGS.filter((phone_type) => {
      if (phone_type === PHONE_TYPE.MOBILE) return true;
      if (phone_type === PHONE_TYPE.VOIP) return dialer === Platform.OUTREACH
      if (phone_type === PHONE_TYPE.WORK) return true
      if (phone_type === PHONE_TYPE.HOME) return [Platform.OUTREACH, Platform.SALESLOFT, Platform.GONG_ENGAGE].includes(dialer)
      if (phone_type === PHONE_TYPE.OTHER) return true;
      return false;
    }).map((v, idx) => {return [v, {'order': idx, 'active': true, 'double_taps': 1}]}))
  },
  [SETTING.NO_AUTO_COMPLETE_TASK]: (dialer) => false,
  [SETTING.ACTION_ON_BAD_NUMBER]: (dialer) => true,
  [SETTING.NO_SUMMARY_AUTOMATION]: (dialer) => false,
  [SETTING.NUMBER_ROTATION_PREFERENCE]: (dialer) => {
    return {'rotation_active': false, 'rotation_style': NUMBER_ROTATION_TYPES.GEOGRAPHIC_NUMBER_MATCH, 'switch_frequency': 10}
  },
  [SETTING.HIDE_WRONG_NUMBER_ELEMENT]: (dialer) => false,
  [SETTING.NO_LOG_ONLY]: (dialer) => false,
  // *** for apollo *** //
  [SETTING.PRIMARY_ALWAYS]: (dialer) => false,
  [SETTING.PRIMARY_FIRST]: (dialer) => false,
  [SETTING.VERIFIED_ALWAYS]: (dialer) => false,
  // *** for outreach *** ///
  [SETTING.OUTREACH_MODAL_CLICK_SETTING]: (dialer) => dialer === Platform.OUTREACH ? OutreachModalOnClickSetting.RECORD_ONE_PARTY : null,
  // *** for hubspot *** ///
  [SETTING.ALL_RECORDS]: (dialer) => true,
  // *** generic *** //
  [SETTING.LINKEDIN_AUTO_OPEN]: (dialer) => false,
  [SETTING.COMPANY_WEBSITE_AUTO_OPEN]: (dialer) => false,
  [SETTING.SALESFORCE_URL_AUTO_OPEN]: (dialer) => false,

  [SETTING.USE_CUSTOM_PHONE_FIELDS]: (dialer) => false,
  [SETTING.DNC_ALWAYS]: (dialer) => false,
  [SETTING.SKIP_TASK_WITHOUT_NUMBER]: (dialer) => false,
  [SETTING.MAX_NUMBER_LINES]: (dialer) => 5
}

export enum SPECIAL_SETTINGS {
  DIALING_ORDER='DIALING_ORDER',
  NUMBER_ROTATION='NUMBER_ROTATION'
}


export const SETTING_TO_SETTING_TYPE: {[k in SETTING]: SETTING_TYPES} = {
  [SETTING.TIME_TO_DIAL]: SETTING_TYPES.RANGE,
  [SETTING.TIME_TO_LOG]: SETTING_TYPES.RANGE,
  [SETTING.AUTO_PROGRESS_LIVE_ACTIVE]: SETTING_TYPES.TOGGLE,
  [SETTING.AUTO_PROGRESS_TIME]: SETTING_TYPES.RANGE,
  [SETTING.HANGUP_RINGING_ACTIVE]: SETTING_TYPES.TOGGLE,
  [SETTING.HANGUP_RINGING_TIME]: SETTING_TYPES.RANGE,
  [SETTING.HANGUP_VOICEMAIL_ACTIVE]: SETTING_TYPES.TOGGLE,
  [SETTING.HANGUP_VOICEMAIL_TIME]: SETTING_TYPES.RANGE,
  [SETTING.HANGUP_MENU_ACTIVE]: SETTING_TYPES.TOGGLE,
  [SETTING.HANGUP_MENU_TIME]: SETTING_TYPES.RANGE,
  [SETTING.MUSIC_WEBSITE]: SETTING_TYPES.SELECTOR,
  [SETTING.LOCAL_CALL_TIME_MIN]: SETTING_TYPES.NUMBER_INPUT,
  [SETTING.LOCAL_CALL_TIME_MAX]: SETTING_TYPES.NUMBER_INPUT,
  [SETTING.VALIDATE_TIMEZONE_VIA_NUMBER]: SETTING_TYPES.TOGGLE,
  [SETTING.DIALING_MODE]: SETTING_TYPES.SELECTOR,
  [SETTING.PHONE_PREFERENCE]: SETTING_TYPES.SELECTOR,
  [SETTING.LINKEDIN_AUTO_OPEN]: SETTING_TYPES.TOGGLE,
  [SETTING.COMPANY_WEBSITE_AUTO_OPEN]: SETTING_TYPES.TOGGLE,
  [SETTING.SALESFORCE_URL_AUTO_OPEN]: SETTING_TYPES.TOGGLE,
  [SETTING.NO_AUTO_COMPLETE_TASK]: SETTING_TYPES.TOGGLE,
  [SETTING.ACTION_ON_BAD_NUMBER]: SETTING_TYPES.TOGGLE,
  [SETTING.NO_SUMMARY_AUTOMATION]: SETTING_TYPES.TOGGLE,
  [SETTING.NUMBER_ROTATION_PREFERENCE]: SETTING_TYPES.SELECTOR,
  [SETTING.PRIMARY_ALWAYS]: SETTING_TYPES.TOGGLE,
  [SETTING.PRIMARY_FIRST]: SETTING_TYPES.TOGGLE,
  [SETTING.VERIFIED_ALWAYS]: SETTING_TYPES.TOGGLE,
  [SETTING.OUTREACH_MODAL_CLICK_SETTING]: SETTING_TYPES.SELECTOR,
  [SETTING.ALL_RECORDS]: SETTING_TYPES.TOGGLE,
  [SETTING.USE_CUSTOM_PHONE_FIELDS]: SETTING_TYPES.TOGGLE,
  [SETTING.DNC_ALWAYS]: SETTING_TYPES.TOGGLE,
  [SETTING.SKIP_TASK_WITHOUT_NUMBER]: SETTING_TYPES.TOGGLE,
  [SETTING.HIDE_WRONG_NUMBER_ELEMENT]: SETTING_TYPES.TOGGLE,
  [SETTING.NO_LOG_ONLY]: SETTING_TYPES.TOGGLE,
  [SETTING.MAX_NUMBER_LINES]: SETTING_TYPES.RANGE
}

export function getSettingInfo (setting: SETTING)  {
  switch (setting) {
    case SETTING.TIME_TO_DIAL: return {'type': SETTING_TYPES.RANGE, 'min': 1, 'max': 30, 'text': '(s)'}
    case SETTING.TIME_TO_LOG: return { 'type': SETTING_TYPES.RANGE, 'min': 1, 'max': 30, 'text': '(s)'}
    case SETTING.AUTO_PROGRESS_LIVE_ACTIVE: return { 'type': SETTING_TYPES.TOGGLE }
    case SETTING.AUTO_PROGRESS_TIME: return { 'type': SETTING_TYPES.RANGE, 'min': 10, 'max': 120, 'text': '(s)' }
    case SETTING.HANGUP_RINGING_ACTIVE: return { 'type': SETTING_TYPES.TOGGLE }
    case SETTING.HANGUP_RINGING_TIME: return { 'type': SETTING_TYPES.RANGE, 'min': 10, 'max': 120, 'text': '(s)' }
    case SETTING.HANGUP_VOICEMAIL_ACTIVE: return { 'type': SETTING_TYPES.TOGGLE }
    case SETTING.HANGUP_VOICEMAIL_TIME: return { 'type': SETTING_TYPES.RANGE, 'min': 1, 'max': 30, 'text': '(s)' }
    case SETTING.HANGUP_MENU_ACTIVE: return { 'type': SETTING_TYPES.TOGGLE }
    case SETTING.HANGUP_MENU_TIME: return { 'type': SETTING_TYPES.RANGE, 'min': 1, 'max': 30, 'text': '(s)' }
    case SETTING.MUSIC_WEBSITE: return { 'type': SETTING_TYPES.SELECTOR, 'options': [{'value': MusicWebsite.SPOTIFY, 'label': 'Spotify'}, {'value': MusicWebsite.YOUTUBE, 'label': 'Youtube'}] }
    case SETTING.LOCAL_CALL_TIME_MIN: return {'type': SETTING_TYPES.NUMBER_INPUT, 'text': 'AM', 'min': 1, 'max': 11, 'separator': 'to'}
    case SETTING.LOCAL_CALL_TIME_MAX: return {'type': SETTING_TYPES.NUMBER_INPUT, 'text': 'PM', 'min': 1, 'max': 11}
    case SETTING.VALIDATE_TIMEZONE_VIA_NUMBER: return {'type': SETTING_TYPES.TOGGLE }
    case SETTING.DIALING_MODE: return { 'type': SETTING_TYPES.SELECTOR, 'options': [{'value': DialingMode.MULTI_NUMBER_ATTEMPT, 'label': 'Try next # if no pickup'}, {'value': DialingMode.SINGLE_NUMBER_ATTEMPT, 'label': 'Only attempt one #'}]}
    case SETTING.PHONE_PREFERENCE: return {'type': SETTING_TYPES.SPECIAL_INPUT }
    case SETTING.LINKEDIN_AUTO_OPEN: return { 'type': SETTING_TYPES.TOGGLE }
    case SETTING.COMPANY_WEBSITE_AUTO_OPEN: return { 'type': SETTING_TYPES.TOGGLE }
    case SETTING.SALESFORCE_URL_AUTO_OPEN: return { 'type': SETTING_TYPES.TOGGLE }
    case SETTING.NO_AUTO_COMPLETE_TASK: return {'type': SETTING_TYPES.TOGGLE}
    case SETTING.ACTION_ON_BAD_NUMBER: return {'type': SETTING_TYPES.TOGGLE}
    case SETTING.NO_SUMMARY_AUTOMATION: return {'type': SETTING_TYPES.TOGGLE}
    case SETTING.NUMBER_ROTATION_PREFERENCE: return {'type': SPECIAL_SETTINGS.NUMBER_ROTATION}
    case SETTING.NO_LOG_ONLY: return {'type': SETTING_TYPES.TOGGLE}
    // dialer specific settings
    case SETTING.PRIMARY_ALWAYS: return {'type': SETTING_TYPES.TOGGLE}
    case SETTING.PRIMARY_FIRST: return {'type': SETTING_TYPES.TOGGLE}
    case SETTING.VERIFIED_ALWAYS: return {'type': SETTING_TYPES.TOGGLE}
    case SETTING.OUTREACH_MODAL_CLICK_SETTING: return {'type': SETTING_TYPES.SELECTOR, 'options': [{'value': OutreachModalOnClickSetting.RECORD_ONE_PARTY, 'label': 'Record one-party'}, {'value': OutreachModalOnClickSetting.RECORD_ALWAYS, 'label': 'Record both parties'}]}
    case SETTING.ALL_RECORDS: return { 'type': SETTING_TYPES.TOGGLE }
    case SETTING.USE_CUSTOM_PHONE_FIELDS: return {'type': SETTING_TYPES.TOGGLE}
    case SETTING.DNC_ALWAYS: return {'type': SETTING_TYPES.TOGGLE}
    case SETTING.SKIP_TASK_WITHOUT_NUMBER: return {'type': SETTING_TYPES.TOGGLE }
    case SETTING.HIDE_WRONG_NUMBER_ELEMENT: return {'type': SETTING_TYPES.TOGGLE }
    case SETTING.MAX_NUMBER_LINES: return {'type': SETTING_TYPES.RANGE, 'min': 1, 'max': 5, 'text': ''}
  }
}

export type AutoDialerSetting = {
    user_id: string
    user_group_id: string
    team_id: string
    platform: Platform
    [SETTING.TIME_TO_DIAL]: number  | null
    [SETTING.TIME_TO_LOG]: number | null
    [SETTING.AUTO_PROGRESS_LIVE_ACTIVE]: boolean | null 
    [SETTING.AUTO_PROGRESS_TIME]: number | null
    [SETTING.HANGUP_RINGING_ACTIVE]: boolean | null
    [SETTING.HANGUP_RINGING_TIME]: number | null
    [SETTING.HANGUP_VOICEMAIL_ACTIVE]: boolean | null
    [SETTING.HANGUP_VOICEMAIL_TIME]: number | null
    [SETTING.HANGUP_MENU_ACTIVE]: boolean | null
    [SETTING.HANGUP_MENU_TIME]: number | null
    [SETTING.MUSIC_WEBSITE]: MusicWebsite | null
    [SETTING.LOCAL_CALL_TIME_MIN]: number | null
    [SETTING.LOCAL_CALL_TIME_MAX]: number | null
    [SETTING.VALIDATE_TIMEZONE_VIA_NUMBER]: boolean | null
    [SETTING.DIALING_MODE]: DialingMode | null
    [SETTING.PHONE_PREFERENCE]: Map<PHONE_TYPE, PhonePreference> | null,
    [SETTING.LINKEDIN_AUTO_OPEN]: boolean | null
    [SETTING.COMPANY_WEBSITE_AUTO_OPEN]: boolean | null
    [SETTING.SALESFORCE_URL_AUTO_OPEN]: boolean | null
    [SETTING.NO_AUTO_COMPLETE_TASK]: boolean | null
    [SETTING.ACTION_ON_BAD_NUMBER]: boolean | null
    [SETTING.NO_SUMMARY_AUTOMATION]: boolean | null
    [SETTING.NUMBER_ROTATION_PREFERENCE]: NumberRotationPreference | null
    [SETTING.NO_LOG_ONLY]: boolean | null
    [SETTING.PRIMARY_ALWAYS]: boolean | null
    [SETTING.PRIMARY_FIRST]: boolean | null
    [SETTING.VERIFIED_ALWAYS]: boolean | null
    [SETTING.OUTREACH_MODAL_CLICK_SETTING]: OutreachModalOnClickSetting | null 
    [SETTING.ALL_RECORDS]: boolean | null
    [SETTING.USE_CUSTOM_PHONE_FIELDS]: boolean | null
    [SETTING.DNC_ALWAYS]: boolean | null
    [SETTING.SKIP_TASK_WITHOUT_NUMBER]: boolean | null
    [SETTING.HIDE_WRONG_NUMBER_ELEMENT]: boolean | null,
    [SETTING.MAX_NUMBER_LINES]: number | null
}


export const SETTING_TO_CONVERT_FROM_BACKEND: {[k in SETTING]: (value: any, allSettings?: Record<string, any>) => any} = {
  [SETTING.TIME_TO_DIAL]: (value) => value,
  [SETTING.TIME_TO_LOG]: (value) => value,
  [SETTING.AUTO_PROGRESS_LIVE_ACTIVE]: (value) => value,
  [SETTING.AUTO_PROGRESS_TIME]: (value) => value,
  [SETTING.HANGUP_RINGING_ACTIVE]: (value) => value,
  [SETTING.HANGUP_RINGING_TIME]: (value) => value,
  [SETTING.HANGUP_VOICEMAIL_ACTIVE]: (value) => value,
  [SETTING.HANGUP_VOICEMAIL_TIME]: (value) => value,
  [SETTING.HANGUP_MENU_ACTIVE]: (value) => value,
  [SETTING.HANGUP_MENU_TIME]: (value) => value,
  [SETTING.MUSIC_WEBSITE]: (value) => value,
  [SETTING.LOCAL_CALL_TIME_MIN]: (value) => value,
  [SETTING.LOCAL_CALL_TIME_MAX]: (value) => value,
  [SETTING.VALIDATE_TIMEZONE_VIA_NUMBER]: (value) => value,
  [SETTING.DIALING_MODE]: (value) => value,
  [SETTING.PHONE_PREFERENCE]: (value) => {
    if (value) return new Map(Object.entries(JSON.parse(value)))
    return null
  },
  [SETTING.NO_AUTO_COMPLETE_TASK]: (value) => value,
  [SETTING.ACTION_ON_BAD_NUMBER]: (value) => value,
  [SETTING.NO_SUMMARY_AUTOMATION]: (value) => value,
  [SETTING.NUMBER_ROTATION_PREFERENCE]: (value) => value ? JSON.parse(value) : null,
  [SETTING.PRIMARY_ALWAYS]: (value) => value,
  [SETTING.PRIMARY_FIRST]: (value) => value,
  [SETTING.VERIFIED_ALWAYS]: (value) => value,
  [SETTING.OUTREACH_MODAL_CLICK_SETTING]: (value) => value,
  [SETTING.ALL_RECORDS]: (value) => value,
  [SETTING.LINKEDIN_AUTO_OPEN]: (value) => value,
  [SETTING.COMPANY_WEBSITE_AUTO_OPEN]: (value) => value,
  [SETTING.SALESFORCE_URL_AUTO_OPEN]: (value) => value,
  [SETTING.USE_CUSTOM_PHONE_FIELDS]: (value) => value,
  [SETTING.DNC_ALWAYS]: (value) => value,
  [SETTING.SKIP_TASK_WITHOUT_NUMBER]: (value) => value,
  [SETTING.HIDE_WRONG_NUMBER_ELEMENT]: (value) => value,
  [SETTING.NO_LOG_ONLY]: (value) => value,
  [SETTING.MAX_NUMBER_LINES]: (value) => value
}

export function jsonSafeToAutodialerSetting(v: Record<string, any>): AutoDialerSetting {
  return {
    user_id: v.user_id,
    user_group_id: v.user_group_id,
    team_id: v.team_id,
    platform: v.platform,
    ...Object.fromEntries(Object.values(SETTING).map((x) => {return [[x], x === SETTING.PHONE_PREFERENCE || v[x] !== null && v[x] !== undefined ? SETTING_TO_CONVERT_FROM_BACKEND[x](v[x], v) : null]}))
  }
}

export function jsonSafeToAutodialerSettings(v: Record<string, any>): AutoDialerSetting[] {
  return v.map((x: Record<string, any>) => jsonSafeToAutodialerSetting(x))
}

export enum AutomationType {
  DISPOSITION = 'DISPOSITION',
  SENTIMENT = 'SENTIMENT',
  CUSTOM = 'CUSTOM',
  PURPOSE = 'PURPOSE',
  VM_INFO = 'VM_INFO'
}

export const AUTOMATION_TYPE_TO_HUMAN_READABLE: {[k in AutomationType]: string} = {
  [AutomationType.DISPOSITION]: 'Disposition',
  [AutomationType.SENTIMENT]: 'Sentiment',
  [AutomationType.CUSTOM]: 'Custom',
  [AutomationType.PURPOSE]: 'Purpose',
  [AutomationType.VM_INFO]: 'Voicemail'
}

export enum TrellusDisposition {
    NO_ANSWER = 'NO_ANSWER',
    LEFT_VOICEMAIL = 'LEFT_VOICEMAIL',
    WRONG_NUMBER = 'WRONG_NUMBER',  
    GATEKEEPER = 'GATEKEEPER',
    NOT_INTERESTED = 'NOT_INTERESTED',
    OTHER_ANSWERED = 'OTHER_ANSWERED',
    SPECIAL_MATCH_ALL = 'SPECIAL_MATCH_ALL'  
}

export const TrellusDispositionIsPrediction: {[k in TrellusDisposition]: boolean} = {
  [TrellusDisposition.NO_ANSWER]: true,
  [TrellusDisposition.LEFT_VOICEMAIL]: true,
  [TrellusDisposition.WRONG_NUMBER]: true,
  [TrellusDisposition.GATEKEEPER]: true,
  [TrellusDisposition.NOT_INTERESTED]: true,
  [TrellusDisposition.OTHER_ANSWERED]: true,
  [TrellusDisposition.SPECIAL_MATCH_ALL]: false
}

export const TrellusDispositionToHumanReadable: {[k in TrellusDisposition]: string} = {
  [TrellusDisposition.NO_ANSWER]: "No Answer",
  [TrellusDisposition.LEFT_VOICEMAIL]: "Left VM",
  [TrellusDisposition.WRONG_NUMBER]: "Wrong/Bad Number",
  [TrellusDisposition.GATEKEEPER]: "Hit Gatekeeper",
  [TrellusDisposition.NOT_INTERESTED]: "Not interested",
  [TrellusDisposition.OTHER_ANSWERED]: "Quick conversation",
  [TrellusDisposition.SPECIAL_MATCH_ALL]: ''
}

export type AutoDialerMapping = {
  user_id: string
  user_group_id: string
  team_id: string
  platform: Platform
  automation_type: AutomationType
  trellus_disposition: TrellusDisposition
  value: string
}

export function jsonSafeToAutoDialerMapping(v: Record<string, any>): AutoDialerMapping {
  return {
    user_id: v.user_id,
    user_group_id: v.user_group_id,
    team_id: v.team_id,
    platform: v.platform,
    automation_type: v.automation_type,
    trellus_disposition: v.trellus_disposition,
    value: v.value
  }
}

export function jsonSafeToAutodialerMappings(v: Record<string, any>): AutoDialerMapping[] {
  return v.map((x: Record<string, any>) => jsonSafeToAutoDialerMapping(x))
}


export type AutoDialerMappingOptions = {
  user_id: string
  platform: Platform
  automation_type: AutomationType 
  options: string[]
}


export function jsonSafeToAutoDialerMappingOptions(v: Record<string, any>): AutoDialerMappingOptions {
  return {
    user_id: v.user_id,
    platform: v.platform,
    automation_type: v.automation_type,
    options: v.options ? JSON.parse(v.options) : []
  }
}

export function jsonSafeToAutodialerMappingOptionsList(v: Record<string, any>): AutoDialerMappingOptions[] {
  return v.map((x: Record<string, any>) => jsonSafeToAutoDialerMappingOptions(x))
}

export type EmailValueProp = {
  email_value_prop_id: string 
  pain_point: string
  prospect_benefits: string
  solution: string
  user_id: string | null
  team_id: string | null
  email_domain: string | null
}

export function jsonSafeToEmailValueProp(v: Record<string, any>): EmailValueProp {
  return {
    email_value_prop_id: v.email_value_prop_id,
    pain_point: v.pain_point,
    prospect_benefits: v.prospect_benefits,
    solution: v.solution,
    user_id: v.user_id,
    team_id: v.team_id,
    email_domain: v.email_domain
  }
}

export function jsonSafeToEmailValueProps(v: Record<string, any>): EmailValueProp[] {
  return v.map((x: Record<string, any>) => jsonSafeToEmailValueProp(x))
}

export enum EmailToneType {
  SOLUTION_CENTERED = 'SOLUTION_CENTERED',
  PROBLEM_CENTERED = 'PROBLEM_CENTERED',
  FORMAL = 'FORMAL',
  CASUAL = 'CASUAL',
  FUNNY = 'FUNNY',
  SHORT = 'SHORT',
}

export const EMAIL_TONE_TYPE_TO_HUMAN_READABLE: {[k in EmailToneType]: string} = {
  [EmailToneType.FORMAL]: 'Formal',
  [EmailToneType.CASUAL]: 'Casual',
  [EmailToneType.FUNNY]: 'Funny',
  [EmailToneType.SHORT]: 'Shorten',
  [EmailToneType.PROBLEM_CENTERED]: 'Problem Centered',
  [EmailToneType.SOLUTION_CENTERED]: 'Solution Centered',
}

export type AIProspectEmail = {
  user_id: string
  prospect_person_id: string
  gen_email: string
  updated_at: Date
}

export function jsonSafeToAIProspectEmail(v: Record<string, any>): AIProspectEmail {
  return {
    user_id: v.user_id,
    prospect_person_id: v.prospect_person_id,
    gen_email: v.gen_email,
    updated_at: jsonSafeValueToDate(v.updated_at)
  }
}

export type LinkedInGeneratedMessage = {
  user_id: string
  platform_person_url: string
  gen_message: string
  updated_at: Date
}

export function jsonSafeToLinkedInGeneratedEmail(v: Record<string, any>): LinkedInGeneratedMessage {
  return {
    user_id: v.user_id,
    platform_person_url: v.platform_person_url,
    gen_message: v.gen_message,
    updated_at: jsonSafeValueToDate(v.updated_at)
  }
}

export type RegisteredTwilioNumber = {
  rep_phone_value: string,
  user_id: string,
  locality: string | null,
  region: string | null,
  postal_code: string | null,
  latitude: number | null,
  longitude: number | null
  nomorobo_spamscore: number | null
}

export function jsonSafeToRegisteredTwilioNumber(v: Record<string, any>): RegisteredTwilioNumber {
  return {
    rep_phone_value: v.rep_phone_value,
    user_id: v.user_id,
    locality: v.locality,
    region: v.region,
    postal_code: v.postal_code,
    latitude: v.latitude,
    longitude: v.longitude,
    nomorobo_spamscore: v.nomorobo_spamscore
  }
}

export function jsonSafeToRegisteredTwilioNumbers(v: Record<string, any>): RegisteredTwilioNumber[] {
  return v.map((x: Record<string, any>) => jsonSafeToRegisteredTwilioNumber(x))
}

export type OptionalTwilioNumber = {
  friendly_name: string,
  phone_number: string,
  locality: string,
  region: string,
  country: string,
  postal_code: string,
  latitude: number,
  longitude: number
}

export function jsonSafeToOptionalTwilioNumber(v: Record<string, any>): OptionalTwilioNumber {
  return {
    friendly_name: v.friendly_name,
    phone_number: v.phone_number,
    locality: v.locality,
    region: v.region,
    country: v.country,
    postal_code: v.postal_code,
    latitude: v.latitude,
    longitude: v.longitude
  }
}

export function jsonSafeToOptionalTwilioNumbers(v: Record<string, any>): OptionalTwilioNumber[] {
  return v.map((x: Record<string, any>) => jsonSafeToOptionalTwilioNumber(x))
}

export enum SESSION_METRICS_COLUMNS {
  SESISON_ID = 'session_id',
  USER_ID = 'user_id',
  AUDIO_DURATION = 'audio_duration',
  PROSPECT_TRANSCRIPT_DURATION = 'prospect_transcript_duration',
  USER_DURATION = 'user_duration',
  CALL_DURATION = 'call_duration',
  INITIAL_RINGING_DURATION = 'initial_ringing_duration',
  NUM_WORDS_PROSPECT = 'num_words_prospect',
  NUM_WORDS_REP = 'num_words_rep',
  LANGUAGE = 'language',
  IS_USER_HANGUP = 'is_user_hangup',
  IS_AUTODIALER_CREATED = 'is_autodialer_created',
  IS_AUTODIALER_HANGUP = 'is_autodialer_hangup',
  IS_CONNECTED = 'is_connected',
  CAN_IMPROVE_EARLY_HANGUP = 'can_improve_early_hangup',
  CAN_IMPROVE_NAVIGATE_MENU = 'can_improve_navigate_menu',
  CONNECT_TIME = 'connect_time',
  CONNECT_IS_LIVE = 'connect_is_live',
  CONNECT_IS_RECORDING = 'connect_is_recording',
  IS_ANSWERED = 'is_answered',
  ANSWERED_TIME = 'answered_time',
  TARGET_DURATION = 'target_duration',
  GATEKEEPER_DURATION = 'gatekeeper_duration',
  VOICEMAIL_DURATION = 'voicemail_duration',
  TARGET_SPEECH = 'target_speech',
  LIVE_DURATION_COL = 'live_duration',
  GATEKEEPER_SPEECH = 'gatekeeper_speech',
  REP_SPEECH_WITH_GATEKEEPER = 'rep_speech_with_gatekeeper',
  REP_SPEECH_WITH_TARGET = 'rep_speech_with_target',
  REP_SPEECH_DURING_VOICEMAIL_DURATION = 'rep_speech_during_voicemail_duration',
  LONGEST_MONOLOGUE_REP_TARGET = 'longest_monologue_rep_target',
  NUMBER_WORDS_REP_ALL_ELIGIBLE = 'number_words_rep_all_eligible',
  DURATION_REP_ALL_ELIGIBLE_SPEECH = 'duration_rep_all_eligible_speech',
  WORDS_PER_SECOND_REP_ALL_ELIGIBLE = 'words_per_second_rep_all_eligible',
  NUMBER_WORDS_TARGET_ELIGIBLE = 'number_words_target_eligible',
  DURATION_TARGET_ELIGIBLE_SPEECH = 'duration_target_eligible_speech',
  WORDS_PER_SECOND_TARGET_ELIGIBLE = 'words_per_second_target_eligible',
  NUMBER_WORDS_REP_TARGET_ELIGIBLE = 'number_words_rep_target_eligible',
  DURATION_REP_TARGET_ELIGIBLE_SPEECH = 'duration_rep_target_eligible_speech',
  WORDS_PER_SECOND_REP_TARGET_ELIGIBLE = 'words_per_second_rep_target_eligible',
  R_DENY_COUNT = 'r_deny_count',
  R_NAME_DENY_COUNT = 'r_name_deny_count',
  R_TITLE_DENY_COUNT = 'r_title_deny_count',
  R_TRANSFER_DENY_COUNT = 'r_transfer_deny_count',
  R_RESP_NOT_DECISION_MAKER_COUNT = 'r_resp_not_decision_maker_count',
  R_RESP_NOT_RELEVANT_COUNT = 'r_resp_not_relevant_count',
  R_CALL_DENY_COUNT = 'r_call_deny_count',
  R_EMAIL_DENY_COUNT = 'r_email_deny_count',
  R_DELAYED_DENY_COUNT = 'r_delayed_deny_count',
  R_MEETING_DENY_COUNT = 'r_meeting_deny_count',
  R_PRODUCT_NO_NEED_COUNT = 'r_product_no_need_count',
  R_PRODUCT_NO_BUDGET_COUNT = 'r_product_no_budget_count',
  R_COMPETITORS_USING_COUNT = 'r_competitors_using_count',
  C_ASKS_COUNT = 'c_asks_count',
  C_REFERRAL_ASK_COUNT = 'c_referral_ask_count',
  C_BUSINESS_ASK_COUNT = 'c_business_ask_count',
  C_RESP_CHECK_COUNT = 'c_resp_check_count',
  C_MEETING_OFFER_COUNT = 'c_meeting_offer_count',
  C_CALL_OFFER_COUNT = 'c_call_offer_count',
  C_EMAIL_OFFER_COUNT = 'c_email_offer_count',
  R_MEETING_CONFIRM = 'r_meeting_confirm',
  R_CALL_APPROVE = 'r_call_approve',
  R_EMAIL_APPROVE_COUNT = 'r_email_approve_count',
  R_EMAIL_APPROVE = 'r_email_approve',
  R_REFERRAL_PROVIDE = 'r_referral_provide',
  R_PRODUCT_INTERESTED_COUNT = 'r_product_interested_count',
  GEN_SUMMARY_TEXT = 'gen_summary_text',
  GEN_EMAIL_TEXT = 'gen_email_text',
  GEN_COACHING_TEXT = 'gen_coaching_text',
  STAGE = 'stage',
  STAGE_DISPOSITION = 'stage_disposition',
  TRELLUS_DISPOSITION = 'trellus_disposition',
  CUSTOM_DISPOSITION = 'custom_disposition',
  USER_DISPOSITION = 'user_disposition',
  USER_SENTIMENT = 'user_sentiment',
  USER_PURPOSE = 'user_purpose',

  PLATFORM = 'platform',
  BEST_COUNTERPART = 'best_counterpart',
  PRIOR_PHONE_USER_NUM_CALLS = 'prior_phone_user_num_calls',
  PRIOR_PHONE_USER_MAX_ANSWERED_DURATION = 'prior_phone_user_max_answered_duration',
  PRIOR_PHONE_USER_ELAPSED_SINCE_FIRST = 'prior_phone_user_elapsed_since_first',
  PRIOR_PHONE_USER_ELAPSED_SINCE_LAST = 'prior_phone_user_elapsed_since_last',
  PRIOR_PHONE_USER_IS_TEAM_FIRST = 'prior_phone_user_is_team_first',
  PRIOR_PHONE_TEAM_NOT_USER_NUM_CALLS = 'prior_phone_team_not_user_num_calls',

  NEXT_C_CALL = 'next_c_call',
  NEXT_C_CALL_BACK = 'next_c_call_back'
} 

export enum PROSPECT_INFO_COLUMNS {
  SESSION_ID = 'session_id',
  USER_ID = 'user_id',
  SCHEDULED_START = 'scheduled_start',
  PLATFORM = 'platform',
  PLATFORM_TENANT_ID = 'platform_tenant_id',
  PLATFORM_PERSON_ID = 'platform_person_id',
  PLATFORM_COMPANY_ID = 'platform_company_id',
  PLATFORM_PERSON_URL = 'platform_person_url',
  PROSPECT_PHONE_VALUE = 'prospect_phone_value',
  PROSPECT_PHONE_TYPE = 'prospect_phone_type',
  XREF_PLATFORM = 'xref_platform',
  XREF_PERSON_ID = 'xref_person_id',
  XREF_COMPANY_ID = 'xref_company_id',
  XREF_PERSON_URL = 'xref_person_url',
  CADENCE = 'cadence',
  CADENCE_2 = 'cadence_2',
  CADENCE_3 = 'cadence_3',
  CADENCE_STEP = 'cadence_step',
  CADENCE_STEP_2 = 'cadence_step_2',
  CADENCE_STEP_3 = 'cadence_step_3',
  PROSPECT_NAME = 'prospect_name',
  PROSPECT_TITLE = 'prospect_title',
  PROSPECT_OCCUPATION = 'prospect_occupation',
  PROSPECT_SENIORITY = 'prospect_seniority',
  PROSPECT_LOCATION = 'prospect_location',
  PROSPECT_LINKEDIN = 'prospect_linkedin',
  PROSPECT_EMAIL = 'prospect_email',
  PROSPECT_PHONE_OTHER_1 = 'prospect_phone_other_1',
  PROSPECT_PHONE_OTHER_2 = 'prospect_phone_other_2',
  COMPANY_NAME = 'company_name',
  COMPANY_LINKEDIN = 'company_linkedin',
  COMPANY_URL = 'company_url',
  COMPANY_INDUSTRY = 'company_industry',
  COMPANY_INDUSTRY_2 = 'company_industry_2',
  COMPANY_INDUSTRY_3 = 'company_industry_3',
  COMPANY_SIZE_MIN = 'company_size_min',
  COMPANY_SIZE_MAX = 'company_size_max',
  COMPANY_PERSONA = 'company_persona',
  PROSPECT_LIST = 'prospect_list',
  PROSPECT_LIST_2 = 'prospect_list_2',
  PROSPECT_LIST_3 = 'prospect_list_3',
  MARKET = 'market',
  LEAD_TYPE = 'lead_type',
}

export const SESSION_METRICS_COLUMNS_TO_HUMAN_READABLE: { [k in SESSION_METRICS_COLUMNS]: string } = {
  [SESSION_METRICS_COLUMNS.SESISON_ID]: 'Session ID',
  [SESSION_METRICS_COLUMNS.USER_ID]: 'User ID',
  [SESSION_METRICS_COLUMNS.AUDIO_DURATION]: 'Audio Duration',
  [SESSION_METRICS_COLUMNS.PROSPECT_TRANSCRIPT_DURATION]: 'Prospect Transcript Duration',
  [SESSION_METRICS_COLUMNS.USER_DURATION]: 'User Duration',
  [SESSION_METRICS_COLUMNS.CALL_DURATION]: 'Call Duration',
  [SESSION_METRICS_COLUMNS.INITIAL_RINGING_DURATION]: 'Initial Ringing Duration',
  [SESSION_METRICS_COLUMNS.NUM_WORDS_PROSPECT]: 'Number of Words by Prospect',
  [SESSION_METRICS_COLUMNS.NUM_WORDS_REP]: 'Number of Words by Representative',
  [SESSION_METRICS_COLUMNS.LANGUAGE]: 'Language',
  [SESSION_METRICS_COLUMNS.IS_USER_HANGUP]: 'Is User Hangup',
  [SESSION_METRICS_COLUMNS.IS_AUTODIALER_CREATED]: 'Is Autodialer Created',
  [SESSION_METRICS_COLUMNS.IS_AUTODIALER_HANGUP]: 'Is Autodialer Hangup',
  [SESSION_METRICS_COLUMNS.IS_CONNECTED]: 'Is Connected',
  [SESSION_METRICS_COLUMNS.CAN_IMPROVE_EARLY_HANGUP]: 'Can Improve Early Hangup',
  [SESSION_METRICS_COLUMNS.CAN_IMPROVE_NAVIGATE_MENU]: 'Can Improve Navigate Menu',
  [SESSION_METRICS_COLUMNS.CONNECT_TIME]: 'Connect Time',
  [SESSION_METRICS_COLUMNS.CONNECT_IS_LIVE]: 'Connect Is Live',
  [SESSION_METRICS_COLUMNS.CONNECT_IS_RECORDING]: 'Connect Is Recording',
  [SESSION_METRICS_COLUMNS.IS_ANSWERED]: 'Is Answered',
  [SESSION_METRICS_COLUMNS.ANSWERED_TIME]: 'Answered Time',
  [SESSION_METRICS_COLUMNS.TARGET_DURATION]: 'Target Duration',
  [SESSION_METRICS_COLUMNS.GATEKEEPER_DURATION]: 'Gatekeeper Duration',
  [SESSION_METRICS_COLUMNS.VOICEMAIL_DURATION]: 'Voicemail Duration',
  [SESSION_METRICS_COLUMNS.TARGET_SPEECH]: 'Target Speech',
  [SESSION_METRICS_COLUMNS.GATEKEEPER_SPEECH]: 'Gatekeeper Speech',
  [SESSION_METRICS_COLUMNS.REP_SPEECH_WITH_GATEKEEPER]: 'Rep Speech with Gatekeeper',
  [SESSION_METRICS_COLUMNS.LIVE_DURATION_COL]: 'Live Duration',
  [SESSION_METRICS_COLUMNS.REP_SPEECH_WITH_TARGET]: 'Rep Speech with Target',
  [SESSION_METRICS_COLUMNS.REP_SPEECH_DURING_VOICEMAIL_DURATION]: 'Rep Speech During Voicemail Duration',
  [SESSION_METRICS_COLUMNS.LONGEST_MONOLOGUE_REP_TARGET]: 'Longest Monologue by Rep to Target',
  [SESSION_METRICS_COLUMNS.NUMBER_WORDS_REP_ALL_ELIGIBLE]: 'Number of Words by Rep (All Eligible)',
  [SESSION_METRICS_COLUMNS.DURATION_REP_ALL_ELIGIBLE_SPEECH]: 'Duration of Rep Speech (All Eligible)',
  [SESSION_METRICS_COLUMNS.WORDS_PER_SECOND_REP_ALL_ELIGIBLE]: 'Words per Second by Rep (All Eligible)',
  [SESSION_METRICS_COLUMNS.NUMBER_WORDS_TARGET_ELIGIBLE]: 'Number of Words by Target (Eligible)',
  [SESSION_METRICS_COLUMNS.DURATION_TARGET_ELIGIBLE_SPEECH]: 'Duration of Target Speech (Eligible)',
  [SESSION_METRICS_COLUMNS.WORDS_PER_SECOND_TARGET_ELIGIBLE]: 'Words per Second by Target (Eligible)',
  [SESSION_METRICS_COLUMNS.NUMBER_WORDS_REP_TARGET_ELIGIBLE]: 'Number of Words by Rep to Target (Eligible)',
  [SESSION_METRICS_COLUMNS.DURATION_REP_TARGET_ELIGIBLE_SPEECH]: 'Duration of Rep Speech to Target (Eligible)',
  [SESSION_METRICS_COLUMNS.WORDS_PER_SECOND_REP_TARGET_ELIGIBLE]: 'Words per Second by Rep to Target (Eligible)',
  [SESSION_METRICS_COLUMNS.R_DENY_COUNT]: 'Denial Count',
  [SESSION_METRICS_COLUMNS.R_NAME_DENY_COUNT]: 'Name Denial Count',
  [SESSION_METRICS_COLUMNS.R_TITLE_DENY_COUNT]: 'Title Denial Count',
  [SESSION_METRICS_COLUMNS.R_TRANSFER_DENY_COUNT]: 'Transfer Denial Count',
  [SESSION_METRICS_COLUMNS.R_RESP_NOT_DECISION_MAKER_COUNT]: 'Response: Not Decision Maker Count',
  [SESSION_METRICS_COLUMNS.R_RESP_NOT_RELEVANT_COUNT]: 'Response: Not Relevant Count',
  [SESSION_METRICS_COLUMNS.R_CALL_DENY_COUNT]: 'Call Denial Count',
  [SESSION_METRICS_COLUMNS.R_EMAIL_DENY_COUNT]: 'Email Denial Count',
  [SESSION_METRICS_COLUMNS.R_DELAYED_DENY_COUNT]: 'Delayed Denial Count',
  [SESSION_METRICS_COLUMNS.R_MEETING_DENY_COUNT]: 'Meeting Denial Count',
  [SESSION_METRICS_COLUMNS.R_PRODUCT_NO_NEED_COUNT]: 'Product: No Need Count',
  [SESSION_METRICS_COLUMNS.R_PRODUCT_NO_BUDGET_COUNT]: 'Product: No Budget Count',
  [SESSION_METRICS_COLUMNS.R_COMPETITORS_USING_COUNT]: 'Competitors Using Count',
  [SESSION_METRICS_COLUMNS.C_ASKS_COUNT]: 'Asks Count',
  [SESSION_METRICS_COLUMNS.C_REFERRAL_ASK_COUNT]: 'Referral Ask Count',
  [SESSION_METRICS_COLUMNS.C_BUSINESS_ASK_COUNT]: 'Business Ask Count',
  [SESSION_METRICS_COLUMNS.C_RESP_CHECK_COUNT]: 'Response Check Count',
  [SESSION_METRICS_COLUMNS.C_MEETING_OFFER_COUNT]: 'Meeting Offer Count',
  [SESSION_METRICS_COLUMNS.C_CALL_OFFER_COUNT]: 'Call Offer Count',
  [SESSION_METRICS_COLUMNS.C_EMAIL_OFFER_COUNT]: 'Email Offer Count',
  [SESSION_METRICS_COLUMNS.R_MEETING_CONFIRM]: 'Meeting Confirmation',
  [SESSION_METRICS_COLUMNS.R_CALL_APPROVE]: 'Call Approval',
  [SESSION_METRICS_COLUMNS.R_EMAIL_APPROVE_COUNT]: 'Email Approval Count',
  [SESSION_METRICS_COLUMNS.R_EMAIL_APPROVE]: 'Email Approval',
  [SESSION_METRICS_COLUMNS.R_REFERRAL_PROVIDE]: 'Referral Provided',
  [SESSION_METRICS_COLUMNS.R_PRODUCT_INTERESTED_COUNT]: 'Product Interest Count',
  [SESSION_METRICS_COLUMNS.GEN_SUMMARY_TEXT]: 'Generated Summary Text',
  [SESSION_METRICS_COLUMNS.GEN_EMAIL_TEXT]: 'Generated Email Text',
  [SESSION_METRICS_COLUMNS.GEN_COACHING_TEXT]: 'Generated Coaching Text',
  [SESSION_METRICS_COLUMNS.STAGE]: 'Stage',
  [SESSION_METRICS_COLUMNS.STAGE_DISPOSITION]: 'Stage Disposition',
  [SESSION_METRICS_COLUMNS.TRELLUS_DISPOSITION]: 'Trellus Disposition',
  [SESSION_METRICS_COLUMNS.CUSTOM_DISPOSITION]: 'Custom Disposition',
  [SESSION_METRICS_COLUMNS.USER_DISPOSITION]: 'Disposition',
  [SESSION_METRICS_COLUMNS.USER_SENTIMENT]: 'Sentiment',
  [SESSION_METRICS_COLUMNS.USER_PURPOSE]: 'Purpose',
  [SESSION_METRICS_COLUMNS.PLATFORM]: 'Platform',
  [SESSION_METRICS_COLUMNS.BEST_COUNTERPART]: 'Best Counterpart',
  [SESSION_METRICS_COLUMNS.PRIOR_PHONE_USER_NUM_CALLS]: 'Prior Phone User: Number of Calls',
  [SESSION_METRICS_COLUMNS.PRIOR_PHONE_USER_MAX_ANSWERED_DURATION]: 'Prior Phone User: Max Answered Duration',
  [SESSION_METRICS_COLUMNS.PRIOR_PHONE_USER_ELAPSED_SINCE_FIRST]: 'Prior Phone User: Elapsed Time Since First',
  [SESSION_METRICS_COLUMNS.PRIOR_PHONE_USER_ELAPSED_SINCE_LAST]: 'Prior Phone User: Elapsed Time Since Last',
  [SESSION_METRICS_COLUMNS.PRIOR_PHONE_USER_IS_TEAM_FIRST]: 'Prior Phone User: Is Team First',
  [SESSION_METRICS_COLUMNS.PRIOR_PHONE_TEAM_NOT_USER_NUM_CALLS]: 'Prior Phone Team (Not User): Number of Calls',
  [SESSION_METRICS_COLUMNS.NEXT_C_CALL]: 'Calling someone else',
  [SESSION_METRICS_COLUMNS.NEXT_C_CALL_BACK]: 'Prospect call back'
};

export const PROSPECT_INFO_COLUMNS_TO_HUMAN_READABLE: { [k in PROSPECT_INFO_COLUMNS]: string } = {
  [PROSPECT_INFO_COLUMNS.SESSION_ID]: 'Session ID',
  [PROSPECT_INFO_COLUMNS.USER_ID]: 'User ID',
  [PROSPECT_INFO_COLUMNS.SCHEDULED_START]: 'Scheduled Start',
  [PROSPECT_INFO_COLUMNS.PLATFORM]: 'Platform',
  [PROSPECT_INFO_COLUMNS.PLATFORM_TENANT_ID]: 'Platform Tenant ID',
  [PROSPECT_INFO_COLUMNS.PLATFORM_PERSON_ID]: 'Platform Person ID',
  [PROSPECT_INFO_COLUMNS.PLATFORM_COMPANY_ID]: 'Platform Company ID',
  [PROSPECT_INFO_COLUMNS.PLATFORM_PERSON_URL]: 'Platform Person URL',
  [PROSPECT_INFO_COLUMNS.PROSPECT_PHONE_VALUE]: 'Prospect Phone Value',
  [PROSPECT_INFO_COLUMNS.PROSPECT_PHONE_TYPE]: 'Phone Type',
  [PROSPECT_INFO_COLUMNS.XREF_PLATFORM]: 'Cross-Reference Platform',
  [PROSPECT_INFO_COLUMNS.XREF_PERSON_ID]: 'Cross-Reference Person ID',
  [PROSPECT_INFO_COLUMNS.XREF_COMPANY_ID]: 'Cross-Reference Company ID',
  [PROSPECT_INFO_COLUMNS.XREF_PERSON_URL]: 'Cross-Reference Person URL',
  [PROSPECT_INFO_COLUMNS.CADENCE]: 'Cadence',
  [PROSPECT_INFO_COLUMNS.CADENCE_2]: 'Cadence 2',
  [PROSPECT_INFO_COLUMNS.CADENCE_3]: 'Cadence 3',
  [PROSPECT_INFO_COLUMNS.CADENCE_STEP]: "Cadence Step",
  [PROSPECT_INFO_COLUMNS.CADENCE_STEP_2]: 'Cadence Step 2',
  [PROSPECT_INFO_COLUMNS.CADENCE_STEP_3]: 'Cadence Step 3',
  [PROSPECT_INFO_COLUMNS.PROSPECT_NAME]: 'Prospect Name',
  [PROSPECT_INFO_COLUMNS.PROSPECT_TITLE]: 'Prospect Title',
  [PROSPECT_INFO_COLUMNS.PROSPECT_OCCUPATION]: 'Prospect Occupation',
  [PROSPECT_INFO_COLUMNS.PROSPECT_SENIORITY]: 'Prospect Seniority',
  [PROSPECT_INFO_COLUMNS.PROSPECT_LOCATION]: 'Prospect Location',
  [PROSPECT_INFO_COLUMNS.PROSPECT_LINKEDIN]: 'Prospect LinkedIn',
  [PROSPECT_INFO_COLUMNS.PROSPECT_EMAIL]: 'Prospect Email',
  [PROSPECT_INFO_COLUMNS.PROSPECT_PHONE_OTHER_1]: 'Prospect Phone (Other 1)',
  [PROSPECT_INFO_COLUMNS.PROSPECT_PHONE_OTHER_2]: 'Prospect Phone (Other 2)',
  [PROSPECT_INFO_COLUMNS.COMPANY_NAME]: 'Company Name',
  [PROSPECT_INFO_COLUMNS.COMPANY_LINKEDIN]: 'Company LinkedIn',
  [PROSPECT_INFO_COLUMNS.COMPANY_URL]: 'Company URL',
  [PROSPECT_INFO_COLUMNS.COMPANY_INDUSTRY]: 'Company Industry',
  [PROSPECT_INFO_COLUMNS.COMPANY_INDUSTRY_2]: 'Company Industry 2',
  [PROSPECT_INFO_COLUMNS.COMPANY_INDUSTRY_3]: 'Company Industry 3',
  [PROSPECT_INFO_COLUMNS.COMPANY_SIZE_MIN]: 'Company Size (Min)',
  [PROSPECT_INFO_COLUMNS.COMPANY_SIZE_MAX]: 'Company Size (Max)',
  [PROSPECT_INFO_COLUMNS.COMPANY_PERSONA]: 'Company Persona',
  [PROSPECT_INFO_COLUMNS.PROSPECT_LIST]: 'Prospect List',
  [PROSPECT_INFO_COLUMNS.PROSPECT_LIST_2]: 'Prospect List 2',
  [PROSPECT_INFO_COLUMNS.PROSPECT_LIST_3]: 'Prospect List 3',
  [PROSPECT_INFO_COLUMNS.MARKET]: 'Market',
  [PROSPECT_INFO_COLUMNS.LEAD_TYPE]: 'Lead Type',
};

export type SavedReport = {
  report_id: string
  user_id: string
  team_id: string
  title: string
  description: string | null
  selects_str: string
  where_str: string
  period_days: number
  is_one_on_one: boolean
  is_published: boolean
  is_active: boolean
}

export function jsonSafeToSavedReport(v: Record<string, any>): SavedReport {
  return {
    report_id: v.report_id,
    user_id: v.user_id,
    team_id: v.team_id,
    title: v.title,
    description: v.description,
    selects_str: v.selects_str,
    where_str: v.where_str,
    period_days: v.period_days,
    is_one_on_one: v.is_one_on_one,
    is_published: v.is_published,
    is_active: v.is_active
  }
}

export function jsonSafeToSavedReports(v: Record<string, any>): SavedReport[] {
  return v.map((x: Record<string, any>) => jsonSafeToSavedReport(x))
}

export type AIManyCallAnalysis = {
  user_id: string
  team_id: string
  period_start: Date
  period_end: Date
  transcripts_analyzed: string
  analysis_raw: string
  analysis: string
}

export function jsonSafeToAIManyCallAnalysis(v: Record<string, any>): AIManyCallAnalysis {
  return {
    user_id: v.user_id,
    team_id: v.team_id,
    period_start: jsonSafeValueToDate(v.period_start),
    period_end: jsonSafeValueToDate(v.period_end),
    transcripts_analyzed: v.transcripts_analyzed,
    analysis_raw: v.analysis_raw,
    analysis: v.analysis,
  }
}

export type MultiDialerSubscription = {
  team_id: string
  max_dials_at_once: number
  max_dials_per_second: number
  max_dials_per_month: number
  max_phone_numbers_per_user: number
  international_credits_per_month: number
}

export function jsonSafeToMultiDialerSubscription(v: Record<string, any>): MultiDialerSubscription {
  return {
    team_id: v.team_id,
    max_dials_at_once: v.max_dials_at_once,
    max_dials_per_second: v.max_dials_per_second,
    max_dials_per_month: v.max_dials_per_month,
    max_phone_numbers_per_user: v.max_phone_numbers_per_user,
    international_credits_per_month: v.international_credits_per_month
  }
}

export type RecordedGreeting = {
  recorded_greeting_id: string
  user_id: string
  name: string
  file_size: number  // size in bytes
}

export function jsonSafeToRecordedGreeting(v: Record<string, any>): RecordedGreeting {
  return {
    recorded_greeting_id: v.recorded_greeting_id,
    user_id: v.user_id,
    name: v.name,
    file_size: v.file_size,
  }
}

export type SalesRoom = {
  room_id: string
  room_name: string
  team_id: string | null
  user_id: string | null
}

export function jsonSafeToSalesRoom(v: Record<string, any>): SalesRoom {
  return {
    room_id: v.room_id,
    room_name: v.room_name,
    team_id: v.team_id,
    user_id: v.user_id,
  }
}

export function jsonSafeToSalesRooms(v: Record<string, any>): SalesRoom[] {
  return v.map((x: Record<string, any>) => jsonSafeToSalesRoom(x))
}

export type SalesRoomInvitation = {
  room_id: string
  invitation_key: string
}

export function jsonSafeToSalesRoomInvitation(v: Record<string, any>): SalesRoomInvitation {
  return {
    room_id: v.room_id,
    invitation_key: v.invitation_key,
  }
}


export enum BotStyle {
  COLD_CALL = 'COLD_CALL',
  FOLLOW_UP_CALL = 'FOLLOW_UP_CALL',
  REFERRAL_CALL = 'REFERRAL_CALL',
  WARM_CALL = 'WARM_CALL'
}


export enum BotLanguage {
  ENGLISH = 'ENGLISH',
  SPANISH = 'SPANISH',
  FRENCH = 'FRENCH'
}

export enum CallBotEmotion {
  NEUTRAL = 'NEUTRAL',
  HAPPY = 'HAPPY',
  SAD = 'SAD',
  ANGRY = 'ANGRY',
  EXCITED = 'EXCITED',
  NERVOUS = 'NERVOUS',
  CURIOUS = 'CURIOUS',
  FRUSTRATED = 'FRUSTRATED',
  CALM = 'CALM',
  EMPATHETIC = 'EMPATHETIC',
  CONFIDENT = 'CONFIDENT',
  DOUBTFUL = 'DOUBTFUL',
  APOLOGETIC = 'APOLOGETIC',
  SKEPTICAL = 'SKEPTICAL',
  OPTIMISTIC = 'OPTIMISTIC',
  PESSIMISTIC = 'PESSIMISTIC',
  SARCASTIC = 'SARCASTIC',
  INDIFFERENT = 'INDIFFERENT',
  URGENT = 'URGENT',
  RELAXED = 'RELAXED'
}

export enum BotVoiceType {
  YOUNG_MAN = 'YOUNG_MAN',
  YOUNG_WOMAN = 'YOUNG_WOMAN',
  MIDDLE_AGED_MAN = 'MIDDLE_AGED_MAN',
  MIDDLE_AGED_WOMAN = 'MIDDLE_AGED_WOMAN',
  OLD_MAN = 'OLD_MAN',
  OLD_WOMAN = 'OLD_WOMAN',
  AUTHORITATIVE_MAN = 'AUTHORITATIVE_MAN',
  AUTHORITATIVE_WOMAN = 'AUTHORITATIVE_WOMAN',
  CALM_MAN = 'CALM_MAN',
  CALM_WOMAN = 'CALM_WOMAN'
}

export type BotCallTranscript = {
  is_rep: boolean
  text: string
  timestamp: number
  duration: number // in seconds
}

export type BotCallAnalysis = {
  opener: {
    politeness_and_professionalism: number
    clarity_and_conciseness: number
    engagement: number,
    personalization: number,
    tone_and_enthusiasm: number,
  },
  qualification: {
    thorough_assessment: number,
    open_ended_questions: number,
    listening_skills: number,
    relevance: number,
    building_rapport: number,
  }, 
  objection_handling: {
    empathy_and_understanding: number,
    clarification: number,
    solution_oriented_responses: number,
    persistence_without_pressure: number,
    case_studies: number,
  }, 
  closing: {
    clear_next_steps: number,
    closing_technique: number,
    confirmation: number,
    politeness_and_sincerity: number,
    follow_up_indication: number,
  }
  overall_evaluation: {
    total_score: number
    overall_commentary: string
  },
}

export const BOT_CALL_ANALYSIS_VERSION = 'bot-analysis-model-v1'

export enum CallLanguage {
  ENGLISH = 'ENGLISH',
  SPANISH = 'SPANISH',
  FRENCH = 'FRENCH',
  DUTCH = 'DUTCH',
  GERMAN = 'GERMAN'
}

export enum CallDifficultyMode {
  EASY = 'EASY',
  MEDIUM = 'MEDIUM',
  HARD = 'HARD'
}

export const CALL_DIFFICULTY_TO_HUMAN_READABLE: {[k in CallDifficultyMode]: string} = {
  [CallDifficultyMode.EASY]: 'Easy',
  [CallDifficultyMode.MEDIUM]: 'Medium',
  [CallDifficultyMode.HARD]: 'Hard'
}

export type BotCall = {
  bot_call_id: string
  bot_id: string
  user_id: string
  team_id: string
  start: Date
  call_score: number | null
  duration: number | null
  call_transcripts: BotCallTranscript[] | null
  call_analysis: BotCallAnalysis | null
  call_analysis_version: string | null
  audio_url: string | null
  difficulty_mode?: CallDifficultyMode // Add this field
  searched?: boolean | null
}

export function getCallTranscriptFromString(s: string | null): BotCallTranscript[] {
  if (!s) return []
  try {
    const parsed = JSON.parse(s)
    return parsed.filter((x: any) => x['role'] && x['role'] !== 'system')
      .map((x: any) => ({
        is_rep: x['role'] === 'user',
        text: x.message,
        timestamp: x.secondsFromStart,
        duration: (x['endTime'] - x['time']) / 1000
      }))
  } catch (e) {
    console.error('Error parsing call transcript string', e)
    return []
  }
}

export function jsonSafeToBotCallAnalysis(v: Record<string, any>): BotCallAnalysis | null {
  if (v === null) return null
  try {
    return {
      opener: {
        politeness_and_professionalism: v.opener.politeness_and_professionalism,
      clarity_and_conciseness: v.opener.clarity_and_conciseness,
      engagement: v.opener.engagement,
      personalization: v.opener.personalization,
      tone_and_enthusiasm: v.opener.tone_and_enthusiasm,
    },
    qualification: {
      thorough_assessment: v.qualification.thorough_assessment,
      open_ended_questions: v.qualification.open_ended_questions,
      listening_skills: v.qualification.listening_skills,
      relevance: v.qualification.relevance,
      building_rapport: v.qualification.building_rapport,
    },
    objection_handling: {
      empathy_and_understanding: v.objection_handling.empathy_and_understanding,
      clarification: v.objection_handling.clarification,
      solution_oriented_responses: v.objection_handling.solution_oriented_responses,
      persistence_without_pressure: v.objection_handling.persistence_without_pressure,
      case_studies: v.objection_handling.case_studies,
    },
    closing: {
      clear_next_steps: v.closing.clear_next_steps,
      closing_technique: v.closing.closing_technique,
      confirmation: v.closing.confirmation,
      politeness_and_sincerity: v.closing.politeness_and_sincerity,
      follow_up_indication: v.closing.follow_up_indication,
    },
    overall_evaluation: {
      total_score: v.overall_evaluation.total_score,
        overall_commentary: v.overall_evaluation.overall_commentary,
      }
    }
  } catch (e) {
    console.error('Error parsing bot call analysis', e)
    return null
  }
}

export function jsonSafeToBotCall(v: Record<string, any>): BotCall {
  return {
    bot_call_id: v.bot_call_id,
    bot_id: v.bot_id,
    user_id: v.user_id,
    team_id: v.team_id,
    start: jsonSafeValueToDate(v.start),
    call_score: v.call_score === -1 ? null : v.call_score,
    call_transcripts: getCallTranscriptFromString(v.call_transcripts),
    call_analysis: v.call_analysis_version === BOT_CALL_ANALYSIS_VERSION ? jsonSafeToBotCallAnalysis(JSON.parse(v.call_analysis)) : null,
    call_analysis_version: v.call_analysis_version,
    duration: v.duration,
    audio_url: v.audio_url,
    difficulty_mode: v.difficulty_level || CallDifficultyMode.EASY // Default to EASY
  }
}

export function jsonSafeToBotCalls(v: Record<string, any>): BotCall[] {
  try {
    return v.map((x: Record<string, any>) => jsonSafeToBotCall(x))
  } catch (e) {
    console.error('Error parsing bot calls', e)
    return []
  }
}

export type BotGeneration = {
  meta_description: string
  background: string
  problems: string
  objections: string
  custom_instructions: string
}

export function jsonSafeToBotGeneration(v: Record<string, any>): BotGeneration {
  return {
    meta_description: v.meta_description,
    background: v.background,
    problems: v.problems,
    objections: v.objections,
    custom_instructions: v.custom_instructions
  }
}

export type Bot = {
  bot_id: string
  team_id: string
  name: string
  meta_description: string
  background_instruction: string
  problem_instruction: string
  objections_instruction: string
  bot_language: BotLanguage
  bot_style: BotStyle
  voice: BotVoiceType
  is_active: boolean
  is_global: boolean
  user_id: string | null
  custom_instructions: string | null
  updated_at: string
  tags: string[] | null
}

export function jsonSafeToBot(v: Record<string, any>): Bot {
  return {
    bot_id: v.bot_id,
    team_id: v.team_id,
    name: v.name,
    meta_description: v.meta_description,
    background_instruction: v.background_instruction,
    problem_instruction: v.problem_instruction,
    objections_instruction: v.objections_instruction,
    bot_language: v.bot_language,
    bot_style: v.bot_style,
    voice: v.voice,
    is_active: v.is_active,
    is_global: v.is_global,
    user_id: v.user_id,
    custom_instructions: v.custom_instructions,
    updated_at: jsonSafeValueToDate(v.updated_at).toISOString(),
    tags: v.tags ? v.tags.split(',') : null
  }
}

export function jsonSafeToBots(v: Record<string, any>): Bot[] {
  return v.map((x: Record<string, any>) => jsonSafeToBot(x))
}

export type BotEmotion = {
  bot_id: string
  emotion: CallBotEmotion
}

export function jsonSafeToBotEmotion(v: Record<string, any>): BotEmotion {
  return {
    bot_id: v.bot_id,
    emotion: v.emotion
  }
}

export function jsonSafeToBotEmotions(v: Record<string, any>): BotEmotion[] {
  return v.map((x: Record<string, any>) => jsonSafeToBotEmotion(x))
}

// Add this near other enums/mappings
export const BotVoiceToAvatarUrl: Record<BotVoiceType, string> = {
  [BotVoiceType.YOUNG_MAN]: '/avatars/young-man.png',
  [BotVoiceType.YOUNG_WOMAN]: '/avatars/young-woman.png',
  [BotVoiceType.MIDDLE_AGED_MAN]: '/avatars/middle-aged-man.png',
  [BotVoiceType.MIDDLE_AGED_WOMAN]: '/avatars/middle-aged-woman.png',
  [BotVoiceType.OLD_MAN]: '/avatars/old-man.png',
  [BotVoiceType.OLD_WOMAN]: '/avatars/old-woman.png',
  [BotVoiceType.AUTHORITATIVE_MAN]: '/avatars/authoritative-man.png',
  [BotVoiceType.AUTHORITATIVE_WOMAN]: '/avatars/authoritative-woman.png',
  [BotVoiceType.CALM_MAN]: '/avatars/calm-man.png',
  [BotVoiceType.CALM_WOMAN]: '/avatars/calm-woman.png'
}

export enum CustomMetricSource {
  GEN_AI = 'GEN_AI',
  PHRASE = 'PHRASE',
  AVERAGE = 'AVERAGE',
  CODE = 'CODE'
}

export enum CustomMetricValueType {
  BOOLEAN = 'BOOLEAN',
  PERCENT = 'PERCENT',
  TEXT = 'TEXT',
  ENUM = 'ENUM'
}

export type CustomDefinition = {
  custom_definition_id: string
  team_id: string
  metric_idx: number | null
  label: string | null
  description: string | null
  order: number | null
  tags: string | null
  value_source: CustomMetricSource
  value_type: CustomMetricValueType
  value_type_enum_str: string | null
  value_gen_str: string | null
  value_gen_desc_str: string | null
  value_phrase_str: string | null
  value_phrase_role: PartyRole | null
  value_average_str: string | null
  value_code_str: string | null

  where_min_duration: number | null
  where_max_duration: number | null
  where_gen_str: string | null
  
  modified_as_of: Date | null
  rebuilt_as_of: Date | null
}

export function jsonSafeToCustomDefinition(v: Record<string, any>): CustomDefinition {
  return {
    custom_definition_id: v.custom_definition_id,
    team_id: v.team_id,
    metric_idx: v.metric_idx,
    label: v.label,
    description: v.description,
    order: v.order,
    tags: v.tags,
    value_source: v.value_source,
    value_type: v.value_type,
    value_type_enum_str: v.value_type_enum_str,
    value_gen_str: v.value_gen_str,
    value_gen_desc_str: v.value_gen_desc_str,
    value_phrase_str: v.value_phrase_str,
    value_phrase_role: v.value_phrase_role,
    value_average_str: v.value_average_str,
    value_code_str: v.value_code_str,
    where_min_duration: v.where_min_duration,
    where_max_duration: v.where_max_duration,
    where_gen_str: v.where_gen_str,
    modified_as_of: jsonSafeValueToDate(v.modified_as_of),
    rebuilt_as_of: v.rebuilt_as_of ? jsonSafeValueToDate(v.rebuilt_as_of) : null
  }
}

export function jsonSafeToCustomDefinitions(v: Record<string, any>): CustomDefinition[] {
  return v.map((x: Record<string, any>) => jsonSafeToCustomDefinition(x))
}



export type CustomMetric = {
  session_id: string
  team_id: string
  scheduled_start: Date
  calculated_as_of: Date
  metric_version: string
  metric_0: number | null
  metric_1: number | null
  metric_2: number | null
  metric_3: number | null
  metric_4: number | null
  metric_5: number | null
  metric_6: number | null
  metric_7: number | null
  metric_8: number | null
  metric_9: number | null
  metric_10: number | null
  metric_11: number | null
  metric_12: number | null
  metric_13: number | null
  metric_14: number | null
  metric_15: number | null
  metric_16: number | null
  metric_17: number | null
  metric_18: number | null
  metric_19: number | null
  text_0: string | null
  text_1: string | null
  text_2: string | null
  text_3: string | null
  text_4: string | null
}

export function jsonSafeToCustomMetric(v: Record<string, any>): CustomMetric {
  return {
    session_id: v.session_id,
    team_id: v.team_id,
    scheduled_start: jsonSafeValueToDate(v.scheduled_start),
    calculated_as_of: jsonSafeValueToDate(v.calculated_as_of),
    metric_version: v.metric_version,
    metric_0: v.metric_0,
    metric_1: v.metric_1,
    metric_2: v.metric_2,
    metric_3: v.metric_3,
    metric_4: v.metric_4,
    metric_5: v.metric_5,
    metric_6: v.metric_6,
    metric_7: v.metric_7,
    metric_8: v.metric_8,
    metric_9: v.metric_9,
    metric_10: v.metric_10,
    metric_11: v.metric_11,
    metric_12: v.metric_12,
    metric_13: v.metric_13,
    metric_14: v.metric_14,
    metric_15: v.metric_15,
    metric_16: v.metric_16,
    metric_17: v.metric_17,
    metric_18: v.metric_18,
    metric_19: v.metric_19,
    text_0: v.text_0,
    text_1: v.text_1,
    text_2: v.text_2,
    text_3: v.text_3,
    text_4: v.text_4
  }
}


export type CustomDetail = {
  session_id: string
  team_id: string
  scheduled_start: Date
  calculated_as_of: Date
  metric_to_raw_json_str: string | null
  metric_to_time_json_str: string | null
  metric_to_explanation_json_str: string | null
}

export function jsonSafeToCustomDetail(v: Record<string, any>): CustomDetail {
  return {
    session_id: v.session_id,
    team_id: v.team_id,
    scheduled_start: jsonSafeValueToDate(v.scheduled_start),
    calculated_as_of: jsonSafeValueToDate(v.calculated_as_of),
    metric_to_raw_json_str: v.metric_to_raw_json_str,
    metric_to_time_json_str: v.metric_to_time_json_str,
    metric_to_explanation_json_str: v.metric_to_explanation_json_str
  }
}